import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'oidc-react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js'; // Import jQuery
import 'bootstrap/dist/js/bootstrap.min.js'; // Import Bootstrap JS
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Provider } from 'react-redux'
import configureStore from './store/index';
const store = configureStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const oidcConfig: any = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY_URL,
  clientId: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URL

};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <Provider store={store} >
          <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
