import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters, Column } from 'react-table';
import axios from "axios";
import upImage from '../../images/up.png';  // Import the image
import networksicon from '../../images/down.png';
import addorg from '../../images/Group 8.svg';
//import tenantlist from  '../../json/tenantlist.json';
// import EditOrganization from './editOrganization';
import { AuditLog } from '../../interface/auditLog';
// import CreateOrganization from './createOrganization';
import moment from 'moment';
import AuditFilter from './auditFilter';
import orgmainicon from '../../images/orgicon.svg';
import { UserDetails } from '../../interface/userDetails';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Pagination from '../utility/pagination';
import {Circles} from 'react-loader-spinner';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {faTableList  } from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';

const columns: Column<AuditLog>[] = [

  {
    Header: 'Entity Name',
    accessor: (row: AuditLog) => row && row.entity && row.entity.name || '',
    disableSortBy:true,
  },
  {
    Header: 'Entity Type',
    accessor: 'entity_type',
    disableSortBy:true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableSortBy:true,
  },
  {
    Header: 'IP Address',
    accessor: 'ip_address',
    disableSortBy:true,
  },
  {
    Header: 'User Agent',
    accessor: 'user_agent',
    disableSortBy:true,
  },
  {
    Header: 'Created on',
    accessor: 'created_on',
    disableSortBy:false,
  },
  {
    Header: 'Created by',
    accessor: (row: AuditLog) => row && row.created_by && row.created_by.display_name || '',
    disableSortBy:true,
  }


];

interface TenantTableProps {
  data: any;
}

const AuditLogTable: React.FC<TenantTableProps> = ({ data }) => {
    const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
    const [tableData, setTableData] = useState<AuditLog[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordPerPage, setrecordPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isFilter, setIsFilter] = useState(false);
    const [filterModal,setFilterModal] = useState(false);
    const [filterAPIMessage,setFilterAPIMessage] = useState("");
    const userData = useSelector((state:any) => state.userReducer.userData);	

    const [sortField, setSortField] = useState('created_on');
    const [sortOrder, setSortOrder] = useState('DESC');
  

    const displayFilterModal = () => {
      setFilterModal(true);
    }
    const closeFilterModal = () => {
      setFilterModal(false);
    }

   

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    rows,
   
    
  } = useTable<any>(
    {
      columns,
      data: tableData,
    },
    useFilters,
    useSortBy,
    usePagination,
    
  );

  const fetchLogs = async (sortingField: string = '', sortingType: string = '') => {
    setLoading(true);
    try {
      const token = data?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const tenant_id = (userData && userData[0]?.user?.roles[0]?.role?.name === 'SYSTEM_ADMIN') ? '*' : userData[0]?.user?.tenants?.length > 0 ? userData[0]?.user?.tenants[0]?.id : null ;

      const tenantURL: string = `${BASE_URL}/reporting-service/tenants/${tenant_id}/audit-logs/?page=${currentPage}&limit=${recordPerPage}&sort_field=${sortingField}&sort_order=${sortingType}`;
      const response = await axios.get(tenantURL, { headers });
      const auditLog = response.data.audit_logs;
      setTotalRecords(auditLog.length);

      const formattedAuditTable = auditLog.map((auditLog: any) => ({
        entity:auditLog.entity,
        entity_type: auditLog.entity_type,
        action: auditLog.action,
        ip_address:auditLog.ip_address,
        user_agent:auditLog.user_agent,
        created_on: moment.unix(parseInt(auditLog.created_on)).format('YYYY-MM-DD'), 
        created_by: auditLog.created_by,
      }));
      setTableData(formattedAuditTable);
      //setTableData(auditLog);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }
  
  useEffect(() => {
    fetchLogs('created_on','ASC');
},[currentPage,recordPerPage,userData])

  const options = [
    { value: 'entity_type', label: 'entity_type' },
    { value: 'action', label: 'action' },
    { value: 'status ', label: 'status ' },
    { value: 'tenant_id  ', label: 'tenant_id  ' },
    { value: 'network_id  ', label: 'network_id  ' },
    { value: 'created_on ', label: 'created_on ' }
  ]

  const handleSorting = (data:any, sort:string) => {

    let sortFieldString = ''
    if(data.id === 'created_on'){
      sortFieldString= 'created_on';
    }
    if(sortFieldString !== '' && !data.disableSortBy){
      setSortField(sortFieldString);
      setSortOrder(sort);
      fetchLogs(sortFieldString,sort);
    }
    
  }


  return (
    <div className="bg-box p-3">
      <div className="App">
      <div className='row'>
      <div className="col-md-1">
      <div className='inactive-con'><FontAwesomeIcon style={{fontSize:'20px'}} icon={faTableList as IconProp} /></div>
        </div>
        <div className="col-md-3">
        <h3 style={{marginLeft:'-23%',marginTop:'1%'}}>Audit log</h3>
        </div>
        <div className="col-md-3">
          <button style={{width:'40%',borderRadius:'20px', marginLeft:'230%'}}  className="btn btn-secondary  btn-sm mr-2"  onClick={() => { displayFilterModal() }}>Filter</button>
        </div>
        <div>
          <span style={{fontSize:'20px',fontWeight:'bold',color:'rgb(0 206 255)'}}>{filterAPIMessage}</span>
        </div>
      </div>
        
       
        <div className="scrollable-table-test" style={{justifyContent:"center"}}>
        {loading ? 
            <div style={{marginTop:"100"}}>
            <Circles color="#7C7AFD" height={50} width={50} wrapperStyle={{justifyContent:"center",marginTop:"150px"}}  />
            </div>
          :
                <table {...getTableProps()} className="table ">
                  <thead className='sticky-top'>
                    {headerGroups.map((headerGroup,ind) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column,colInd) => (

                           <th key={colInd+"_ind_"+ind} onClick={()=>handleSorting(column, sortOrder === 'DESC'?'ASC':'DESC')}>
                           {column.render('Header')}
                           {
                             !column.disableSortBy && 
                             <span>
                             {
                               
                               sortOrder === 'DESC' ? (
                                 <img src={upImage}  className='icon-left-m' alt="Down Arrow" onClick={()=>handleSorting(column,'ASC')} />
       
                               ) : (
                                 <img src={networksicon} className='icon-left-m' alt="Up Arrow" onClick={()=>handleSorting(column,'DESC')} />
                               )
                             }
                             
                           </span>
                           }
                           
                         </th>
                          // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          //   {column.render('Header')}
                          //   <span>
                          //     {column.isSortedDesc ? (
                          //       <img src={upImage} className='icon-left-m' alt="Down Arrow" />

                          //     ) : (
                          //       <img src={networksicon} className='icon-left-m' alt="Up Arrow" />
                          //     )}
                          //   </span>
                          // </th>


                        ))}
                      </tr>
                    ))}
                  </thead>
                
                  <tbody {...getTableBodyProps()}>
                              
                    {rows.map((row) => {
                       prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}

                        </tr>
                      );
                    })}
                  </tbody>
              </table>
         } 
             

        </div>


        <div className="paginationOuterDiv">
          <div style={{ width: "35%" }}>
          <span>Select # of Rows  &nbsp;</span>
            <select
              value={recordPerPage}
              onChange={(e) => {
                setrecordPerPage(Number(e.target.value)); setCurrentPage(1);
              }}
              style={{ width: 50, marginTop: 10, textAlign:"center"  }}
            >
              {[25, 50, 75, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "65%" }}>
            <Pagination key={currentPage}
              totalRows={totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={recordPerPage}
              setFilter={setIsFilter}
              currentPageNumber={currentPage}
            />
          </div>
        </div>
       
           <AuditFilter data={data} isAddOpen={filterModal} closeFilterModal={closeFilterModal}  mapTableData={setTableData} filterResponseMsg={setFilterAPIMessage} />       
      </div>
    </div>

  );
}

export default AuditLogTable;

