
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import './auditFilter.css';
import { useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";


interface auditFilterProps {
  data: any;
  isAddOpen: boolean;
  closeFilterModal: any;
  mapTableData: any;
  filterResponseMsg: any;
}

const AuditFilter: React.FC<auditFilterProps> = ({ data, isAddOpen, closeFilterModal, mapTableData, filterResponseMsg }) => {


  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      entityType: '',
      action: '',
      startDate: '',
      endDate: '',
      status: '',
      tenantId: '',
      networkId: ''

    }
  });

  const userData = useSelector((state: any) => state.userReducer.userData);
  const [modalOpen, setModalOpen] = useState(isAddOpen);
  const [selectedEntity, setSelectedEntity] = useState('');
  const [actionList, setActionList] = useState(['CREATE', 'UPDATE', 'DELETE']);
  const entityList = ['DEVICE', 'NETWORK'];
  const statusList = ['PENDING_ONBOARDING', 'PENDING_ONBOARDING_APPROVAL', 'ONBOARDING_APPROVED', 'ONBOARDING_REJECTED', 'ONBOARDED', 'ROGUE', 'CLONED'];
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [selected, setSelected] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [selectedEntityValue, setSelectedEntityValue] = useState<string>('');
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setModalOpen(isAddOpen);
  }, [isAddOpen]);


  useEffect(() => {

    fetchTenant('', '', 'DESC');

  }, []);

  useEffect(() => {
    fetchNetworkData('', '', 'DESC');
  }, []);




  const onSubmit = async (data: {

    entityType: string,
    action: string,
    startDate: string,
    endDate: string,
    status: string,
    tenantId: string,
    networkId: string
  }) => {

    // if (data.startDate !== '' && data.endDate !== '' &&
    //    data.status !== '' &&
    //   data.tenantId !== '' &&
    //   data.networkId !== '' ) {
    //   const responseData: any = await filterAuditLog(data);

    // } 

    const responseData: any = await filterAuditLog(data);


  };


  const filterAuditLog = async (auditLog: any) => {

    try {
      const token = data?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      let tenantURL: string;

      // NEED TO UPDATE THIS IN PROGRESS CODE
      if (selectedEntity === "DEVICE") {
        if (auditLog.action === "ALL" && auditLog.status === "ALL") {
          tenantURL = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
        } else if (auditLog.action === "ALL" && auditLog.status !== "ALL") {
          tenantURL = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&status=${auditLog.status}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
        } else if (auditLog.action !== "ALL" && auditLog.status === "ALL") {
          tenantURL = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&action=${auditLog.action}&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
        } else {
          tenantURL = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&action=${auditLog.action}&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&status=${auditLog.status}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
        }
      } else {

        if (auditLog.action === "ALL") {
          tenantURL = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
        } else {
          tenantURL = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&action=${auditLog.action}&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
        }
      }


      // const tenantURL: string = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&action=${auditLog.action}&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&status=${auditLog.status}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;

      // const tenantURL: string = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?page=${currentPage}&limit=${recordPerPage}&sort_field=name&sort_order=ASC`;

      // const tenantURL: string = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&action=${auditLog.action}&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&status&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;

      // const tenantURL: string = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&status&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;

      // Data URL
      //  const tenantURL: string = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&entity_type=${auditLog.entityType}&tenant_id=${auditLog.tenantId}`;

      // const tenantURL: string = `${BASE_URL}/reporting-service/tenants/*/audit-logs/?limit=10&page=1&action=${auditLog.action}&entity_type=${auditLog.entityType}&sort_field=name&sort_order=ASC&tenant_id=${auditLog.tenantId}&network_id=${auditLog.networkId}&status=${auditLog.status}&created_on=${Date.parse(auditLog.startDate)},${Date.parse(auditLog.endDate)}`;
      closeFilterModal();
      const response = await axios.get(tenantURL, { headers });

      const auditFilterLog = response.data.audit_logs;


      if (auditFilterLog.length > 0) {
        filterResponseMsg("Filter Success");
      } else {
        filterResponseMsg("No Record");
      }


      const formattedAuditTable = auditFilterLog.map((auditLog: any) => ({
        entity: auditLog.entity,
        entity_type: auditLog.entity_type,
        action: auditLog.action,
        ip_address: auditLog.ip_address,
        user_agent: auditLog.user_agent,
        created_on: moment.unix(parseInt(auditLog.created_on)).format('YYYY-MM-DD'),
        created_by: auditLog.created_by,
      }));
      mapTableData(formattedAuditTable);

    } catch (error) {
      closeFilterModal();
      console.error('Error fetching data:', error);
    }

  }


  const fetchTenant = async (searchString: string = '', sortingField: string = '', sortingType: string = '') => {

    try {
      const token = data?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const tenantURL: string = `${BASE_URL}/auth-service/tenants/?k=${searchString}&page=1&limit=25&sort_field=${sortingField}&sort_order=${sortingType}`;
      const response = await axios.get(tenantURL, { headers });
      const tenantDetails = response.data.tenants;
      setTenantData(tenantDetails);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchNetworkData = async (searchString: string = '', sortingField: string = '', sortingType: string = '') => {

    try {

      const token = data?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const tenant_id = (userData && userData[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null;


      try {
        const networkURL: string = `${BASE_URL}/asset-service/tenants/${tenant_id}/networks/?page=1&limit=25&k=${searchString}&sort_field=${sortingField}&sort_order=${sortingType}`;
        const response = await axios.get(networkURL, {
          headers
        });
        const networkList = response.data.networks;
        setNetworkData(networkList);


      } catch (error) {
        console.error('Error fetching data:', error);

      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleEntityType = (data: any) => {
    setSelectedEntityValue(data.target.value);
    setSelectedEntity(data.target.value)
    if (data.target.value === 'DEVICE') {
      setActionList(['CREATE', 'UPDATE', 'DELETE', 'ONBOARDING', 'REPROVISIONED', 'AUTHENTICATION', 'IDENTITY'])
    }
    if (data.target.value === 'NETWORK') {
      setActionList(['CREATE', 'UPDATE', 'DELETE'])
    }

  }



  const CustomTypeaheadSelect = (props: any) => {

    // const [field, meta, helper] = useField(props);
    return (
      // <>
      <div>
        {/* <Form.Group controlId={`form-${props.name}`}> */}
        {/* <Form.Label>{props.label}</Form.Label> */}
        <Typeahead
          id={props.name}
          multiple={false}
          // onChange={(selected) => {
          //   const value = selected.length > 0 ? selected[0].user : "";
          //   helper.setValue(value);
          // }}
          onChange={props.onChange}
          // onInputChange={(text) => helper.setValue(text)}
          // onBlur={() => helper.setTouched(true)}
          allowNew={false}
          labelKey={props.name}
          options={props.options}
        // {...(meta.touched && meta.error
        //   ? { isInvalid: true, className: "is-invalid" }
        //   : { isValid: true })}
        // {...props}
        />
      </div>
    );
  };

  return (

    <div>
      <Modal dialogClassName='audit-filter-dialog' backdrop="static" show={modalOpen} onHide={() => { closeFilterModal(); }} >

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={selectedEntityValue === "DEVICE" ? 'audit-filter-popup-Device' : 'audit-filter-popup-Network'}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><h3 className="titelcolostyle editOrganizationHeader">
                <span className="editOrganizationHeaderText">Audit Filter</span></h3></h5><button type="button" className="btn-close closealiment" onClick={() => {
                  closeFilterModal();
                  reset();
                  setSelectedEntity('');
                }}
                  data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className="col-md-12 text-right">
                  <span className='clear-filter' style={{ cursor: 'pointer' }} onClick={() => { reset(); }}>Clear</span>
                </div>

              </div>
              <div className="row">
              <div className='audit-form'>
                <form>
                  <div className="row">
                    <label className='entity_label font-weight entity-type'>Select Network or Device:<span className="require">*</span></label>
                  </div>
                  <div className="row entity-selection">

                    <div className="col-md-6">
                      <select
                        className='form-select entity-type-select'
                        value={selectedEntityValue}
                        id="entityType"
                        {...register('entityType', {
                          required: true
                        })}
                        onChange={(e) => handleEntityType(e)}
                      >
                        <option value="">Select an option</option>
                        {entityList.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {errors.entityType &&
                        <p className="invalid-feedback d-block errorFont">Entity type is required.</p>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {selectedEntity !== '' &&
              <div>
                <div className='row'>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className='font-weight'>Choose Organization Name:<span className="require">*</span></label>
                      <CustomTypeaheadSelect
                        label="Username"
                        id="tenantId"
                        {...register('tenantId', {
                          required: true
                        })}
                        name="name"
                        onChange={(tenantId: any) => {
                          setValue('tenantId', tenantId[0].id);
                        }}
                        options={tenantData}
                      />
                      {errors.tenantId &&
                        <p className="invalid-feedback d-block errorFont">Tenant is required.</p>}

                    </div>
                  </div>
                  {selectedEntity == 'DEVICE' &&
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className='font-weight'>Enter Network Id: <span className="require"></span></label>
                        {/* <input
                      type="text"
                      placeholder="Enter Network Id"
                      onChange={(e) => {(e.target.value.length >= 3 || e.target.value.length === 0) && fetchNetworkData(e.target.value,'','ASEC'); }}
                    /> */}
                        <CustomTypeaheadSelect name="name"
                          label="Username"
                          id="networkId"
                          onChange={(network: any) => {
                            setValue('networkId', network[0].id);
                          }}
                          options={networkData} />
                      </div>
                    </div>
                  }

                  {selectedEntity == 'DEVICE' &&
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className='font-weight'>Choose Status: <span className="require"></span></label>
                        <select
                          className='form-select'
                          id="status"
                        // {...register('status', { 
                        //   required: true
                        // })}
                        >
                          <option value="">Select an option</option>
                          <option value="ALL">ALL</option>
                          {statusList.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        {/* {errors.status && 
                      <p className="invalid-feedback d-block errorFont">Status is required.</p>} */}
                      </div>
                    </div>
                  }


                  <div className="col-md-6">
                    <div className="form-group">
                      <label className='font-weight'>Choose Action: <span className="require"></span></label>
                      <select
                        className='form-select'
                        id="action"
                      // {...register('action', {
                      //   required: true
                      // })}
                      >
                        <option value="">Select an option</option>
                        <option value="ALL">ALL</option>
                        {actionList.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {/* {errors.action && 
                      <p className="invalid-feedback d-block errorFont">Action is required.</p>} */}

                    </div>
                  </div>

                </div>



                <div className='row'>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className='font-weight'>Enter Start Date: <span className="require"></span></label>
                      {/* <DatePicker selected={startDate} onChange={(date:any) => setStartDate(date)} /> */}
                      <input
                        type="date"
                        className="startData"
                        id="startDate"
                      // {...register('startDate', { 
                      //   required: true
                      // })}
                      />
                      {/* {errors.startDate && 
                      <p className="invalid-feedback d-block errorFont">Start date is required.</p>} */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className='font-weight'>Enter End Date: <span className="require"></span></label>
                      <input
                        type="date"
                        className="endDate"
                        id="endDate"
                      // {...register('endDate', { 
                      //   required: true
                      // })}
                      />
                      {/* {errors.endDate && 
                      <p className="invalid-feedback d-block errorFont">End date is required.</p>} */}
                    </div>
                  </div>

                </div>

              </div>
            }
            <div className='row'>
              <button type="submit" className="btn btn-secondary btn-sm audit-filter-search"
              >Search </button>
            </div>
            </div>
            {/* modal boody ends */}

            

          </div>
        </form>
      </Modal>
    </div>

  )
}

export default AuditFilter;


