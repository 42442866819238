
import orgicon from '../../images/orgicon.svg';
import { useState, ChangeEvent, useEffect } from 'react';
import { Network } from '../../interface/network';
import axios from 'axios';
import './editNetwork.css';
import DeleteNetwork from './deleteNetwork';
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';
import Select from 'react-select';
interface DropdownOption {
  value: string;
  label: string;
}
interface editNetworkProps {
  data: Network | null;
  // onEdit: (newData: Network[]) => void;
  // onDelete: (id: any) => void;
  onEdit: (message:any) => void;
  onDelete:(message:any) => void;
  userData: any;
  network:Network;
  isOpen:boolean;
  closeModal:any;
}

interface FormState {
  name: string;
  description: string;
}


const EditNetwork: React.FC<editNetworkProps> = ({ data, onEdit, onDelete, userData,network, isOpen, closeModal }) => {
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      organizationName: '', networkName: ''
    }
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  const userDetails = useSelector((state: any) => state.userReducer.userData);
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const displayDeleteModal = () => {
    setShowDeleteModal(true);
  }
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }


  const fetchOrganizationData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenant_id = (userDetails && userDetails[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userDetails[0].user.tenants.length > 0 ? userDetails[0].user.tenants[0].id : null;
      const tenantURL: string = `${BASE_URL}/auth-service/tenants/?page=1&limit=100`;
      const response = await axios.get(tenantURL, { headers });
      let organizationsList = response.data.tenants;
      if(tenant_id !== '*'){
        organizationsList = organizationsList.filter((item:any) => (item.id === tenant_id))
      }
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = organizationsList.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setOptions(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching industry data:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationData();

    if (network !== null) {
      setValue('networkName', network?.name);
      setValue('organizationName', network?.tenant.id);
    }

  }, [network])
  const resetEdit = () => {
    if (network !== null) {
      setValue('networkName', network?.name);
      setValue('organizationName', network?.tenant.id);
    }
  } 

  const editData = async (newdata: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantId = data?.tenant.id;
      const networkId = data?.id;
      let editNetwork = {
        name: newdata?.networkName,
        description: "Modified by new data",

      }
      const baseURL: string = `${BASE_URL}/asset-service/tenants/${tenantId}/networks/${networkId}/`;

      const response = await axios.put(baseURL, editNetwork, { headers });
      return { status: true, message: 'created successfully', data: response }
    } catch (error: any) {
      return { status: false, message: error.response.data.error.message, data: {} }
    }

  };

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };


  const onSubmit = async (data: {
    organizationName: string, networkName: string
  }) => {
    // data.organizationName = selectedValue;
    data.networkName = capitalizeFirstLetter(data.networkName);
    if (data.organizationName !== '' && data.networkName !== '') {
      const responseData: any = await editData(data);
      //create new User Map with new Organization
      if (!responseData.status) {
        setErrorMessage(responseData.message);
        setSuccessMessage(null);
        // onEdit({status:false, message:responseData.message});
        return false;
      } else {
        // setSuccessMessage("Network updated successfully");
        setEditForm(true)
        setErrorMessage(null);
        // if (undefined !== responseData.data)
        closeModal(); setModalOpen(false); reset();
        onEdit({status:true, message:"Network updated successfully"});

        return false;
      }
    } else {
      setErrorMessage(null);
      setSuccessMessage(null);
    }

  };

  const handleDelete = (message: string) => {
    onDelete(message);
  }
  const handleOrgChange=()=>{

  }

  return (
    // <div className="modal fade " id="exampleModal"
    //   tabIndex={-1} aria-labelledby="exampleModalLabel"
    //   aria-hidden="true">
    <div>
       <Modal dialogClassName='edit-network-dialog' backdrop="static" show={modalOpen} onHide={() => { closeModal(); setModalOpen(false) }} >
      <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><h3 className="titelcolostyle networkEditHeader">
            <FontAwesomeIcon icon={faNetworkWired as IconProp} />
               <span className="networkEditTxt">Network</span>
               </h3></h5>
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              onClick={() => {
                setErrorMessage(null); setSuccessMessage(null); setEditForm(true);
              }}></button> */}
               <button type="button" className="btn-close"  aria-label="Close"
              onClick={() => {
                setErrorMessage(null); setSuccessMessage(null); setEditForm(true); closeModal();setModalOpen(false);
              }}></button>
          </div>
          <form className="edit-network-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                {null !== errorMessage &&
                  <div>
                    <p style={{ color: 'red' }}>Error: {errorMessage}</p>
                  </div>
                }
                {
                  null !== successMessage &&
                  <div>
                    <p style={{ color: 'blue' }}>Success: {successMessage}</p>
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="label">Organization Name <span className="require">*</span></label>
                    <Select
                            isDisabled={true}
                            className={`basic-single ${errors.organizationName ? 'is-invalid' : ''} selectFieldFont`}
                            classNamePrefix="select"
                            id="organizationName"
                            {...register('organizationName', {
                              required: true,
                            })}
                            onChange={(e)=>handleOrgChange()}
                             options={options}
                            isSearchable={true}
                            value={network &&
                              options.filter(org =>
                                org.value === network?.tenant.id)
                            }
                          />
                    {errors.organizationName && errors.organizationName.type === "required" &&
                      <p className="invalid-feedback d-block">Organization Name is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="label">Network Name<span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.networkName ? 'is-invalid' : ''}`}
                      placeholder="Enter Network Name"
                      {...register('networkName', {
                        required: true,
                      })}
                      disabled={editForm}
                    />
                    {errors.networkName && errors.networkName.type === "required" &&
                      <p className="invalid-feedback d-block">Network Name is required.</p>}
                  </div>
                </div>
              </div>

            </div>
            <div className="modal-footer">
              {
                editForm &&
                <>
                  {/* <button type="button" className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleDeleteNetwork" disabled={!editForm}
                  >Delete</button> */}
                  <button type="button" className="btn btn-danger btn-sm"
                    disabled={!editForm} onClick={() => displayDeleteModal()}
                  >Delete</button>
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => setEditForm(false)}
                    disabled={!editForm}>Edit </button>
                </>
              }


              {
                !editForm &&
                <>
                  <button type="submit" className="btn btn-secondary btn-sm">Save</button>
                  <button type="button" className="btn btn-light btn-sm" onClick=
                    {() => { setErrorMessage(null); setSuccessMessage(null); setEditForm(true);resetEdit(); }} data-bs-dismiss="modal"
                  >Cancel </button>
                </>
              }

            </div>
          </form>
       
      </div>
      {
        showDeleteModal &&
        <DeleteNetwork data={data} onDelete={onDelete} userData={userData} isModalOpen={showDeleteModal} closeModal={closeModal} closeDeleteModal={closeDeleteModal} />
      }
    
      </Modal>
    </div>
  )
}

export default EditNetwork;