import React, {useEffect,useState} from 'react';

const timestampToDaysHrsMin = (seconds) =>{

  seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d;
    var hDisplay = h;
    var mDisplay = m;
    var sDisplay = s;
    if(seconds>0){
      return ((dDisplay!=="" && dDisplay!=0) ?dDisplay+"Days ":"")+hDisplay+":"+mDisplay;
    }else{
      return "-";
    }
}
export {
  timestampToDaysHrsMin

}
