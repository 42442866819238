// src/NewPage.tsx

import React from 'react';
import { NavLink } from "react-router-dom";
import contactimage from "../../images/5143151.jpg";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCells,faHouseLaptop,faNetworkWired,faMicrochip,faAddressBook  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
const ContactTable: React.FC = () => {
  return (
    <div>
     <div className="row">
        <div className="col-md-12">
            <div className="bg-box p-3">
            <h3 className='titelcolostyle'><FontAwesomeIcon icon={faAddressBook as IconProp} className='pageicon'/> Contact Us</h3>
            <div className="container">
      <div className="row">
      <div className="col-md-6">
        <img src={contactimage}  className='contanct-images' />
        </div>
        <div className="col-md-6">
          <form className="add-device-form">
            <div className="row">
              <div className="col-md-12">
               
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control" placeholder="Enter name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" className="form-control" placeholder="Enter email address" />
                </div>
                <div className="form-group">
                  <label>Are You?</label>
                  <div className="org-select">
                    <select className="custom-select">
                      <option value="">Select</option>
                      <option value="1">Government</option>
                      <option value="2">Industry</option>
                      <option value="3">Service Provider</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>Your Interest</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" ></textarea>
                </div>
              </div>
            </div>
            <div className="form-buttons mt-3 text-right mb-3">
              <img src="" alt="" />
              <button type="submit" className="btn btn-secondary btn-sm mr-2">Submit</button>
            </div>
            <div className="contact-footer">
              <span>OR Call us</span>
              <span>
                <img src="/static/media/icon-phone.03efff87239af506436ad65212658112.svg" alt="" />
                +1 (202) 379-4884
              </span>
              <span>
                <img src="/static/media/icon-mail.a03a69917397cd11482038a146b4fc8b.svg" alt="" />
                <a href="mailto:info@qwerx.co">info@qwerx.co</a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
            </div>
    </div>
    </div>
    </div>

  );
};

export default ContactTable;
