
import orgicon from '../../images/orgicon.svg';
import {  ChangeEvent, useEffect, useState } from 'react';
import { device } from '../../interface/device';
import { useForm } from 'react-hook-form';
import { Modal } from "react-bootstrap";
import DeleteDevice from './deleteDevice';
import axios from 'axios';
import './editDevice.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDownload, faMicrochip, faLaptop  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';
import Select from 'react-select';
interface editDeviceProps {
    data:device;
    userData:any;
    deviceDetailsInfo:device;
    onEdit: (message:any) => void;
    // onDelete: (deviceId:any) => void;
    onDelete:(message:any) => void;
    isOpen:boolean;
    closeModal:any;
    
}

interface DropdownOption {
  value: string;
  label: string;
}



const EditDevice: React.FC<editDeviceProps> = ( {userData,data,onEdit,onDelete,deviceDetailsInfo,isOpen,closeModal,} ) => {

  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  // const [tenantId, setTenantId] = useState<string>('');
  const [networks, setNetworks] = useState<DropdownOption[]>([]);
  const [networkId, setNetworkId] = useState<string>('');
  const [editForm, setEditForm] = useState<boolean>(true);
  const [supervisors, setSupervisor] = useState<DropdownOption[]>([]);
  const [userExist, setUserExist] = useState(true)
  const [userExistData, setUserExistData] = useState<any>(null)
  const { register, handleSubmit, reset, setValue,formState: { errors } } = useForm({
    defaultValues: {
      organizationName: '',
      macAddress: '', name: '', networkName: '', email: '', ownerFirstName: '', ownerLastName: '', ownerPhone: '', supervisor: '', department: '', employeeId: '', user_id:''
    }
  });
  const[deviceDetails,setDeviceDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  const [userExistWithOtherOrg, setUserExistWithOtherOrg] = useState(false)
  const userDetails = useSelector((state: any) => state.userReducer.userData);
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const displayDeleteModal = () => {
    setShowDeleteModal(true);
  }
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }



  const mapData = (data:any)=>{
   
  }

 //onload data
//  useEffect(() => {
  
//  },[options]);
 useEffect(() => {
  
  fetchOrganizationData();
  
  if(deviceDetailsInfo != null){
    setValue('organizationName', deviceDetailsInfo?.tenant.id);
    // handleSelectElement(deviceDetailsInfo?.tenant.id);
    
    // setValue('name',deviceDetailsInfo.name);
    // setValue('macAddress', deviceDetailsInfo.mac_address);
    // setValue('email', deviceDetailsInfo.user.email);
    // setValue('ownerFirstName', deviceDetailsInfo.user.first_name);
    // setValue('ownerLastName', deviceDetailsInfo.user.last_name);
    // setValue('ownerPhone', '');
    // setValue('employeeId','');
    // setValue('department', '');
    // setValue('supervisor', '');
    
    fetchNetworkData(deviceDetailsInfo?.tenant.id).then(()=>{
      setValue('networkName',deviceDetailsInfo && deviceDetailsInfo.networks[0].id);
      setValue('name',deviceDetailsInfo.name);
    //  setValue('macAddress', deviceDetailsInfo.mac_address);
    if(deviceDetailsInfo.mac_addresses){
      setValue('macAddress', deviceDetailsInfo?.mac_addresses[0]?.mac_address);
    }
     setValue('email', deviceDetailsInfo.user.email);
      setValue('ownerFirstName', deviceDetailsInfo.user.first_name);
      setValue('ownerLastName', deviceDetailsInfo.user.last_name);
      setValue('ownerPhone', '');
      setValue('employeeId','');
      setValue('department', '');
      setValue('supervisor', '');
      setValue('networkName',deviceDetailsInfo && deviceDetailsInfo.networks[0].id);
    });
   
   
  }
 
  
  // }, [data,networks.length]);
    
}, [data]);

const resetEdit = () => {
  if(deviceDetailsInfo != null){
    setNetworkId('');
    console.log(deviceDetailsInfo.networks[0].id);
    setValue('organizationName', deviceDetailsInfo?.tenant.id);
    // handleSelectElement(deviceDetailsInfo?.tenant.id);
    setValue('networkName',deviceDetailsInfo.networks[0].id);
    
    setValue('name',deviceDetailsInfo.name);
    // setValue('macAddress', deviceDetailsInfo.mac_address);
    if(deviceDetailsInfo?.mac_addresses){
      setValue('macAddress', deviceDetailsInfo?.mac_addresses[0]?.mac_address);
    }
    setValue('email', deviceDetailsInfo.user.email);
    setValue('ownerFirstName', deviceDetailsInfo.user.first_name);
    setValue('ownerLastName', deviceDetailsInfo.user.last_name);
    setValue('ownerPhone', '');
    setValue('employeeId','');
    setValue('department', '');
    setValue('supervisor', '');
    // setValue('networkName',deviceDetailsInfo && deviceDetailsInfo.networks[0].id);
    
    
   
   
  }
}  
  //Fetch Organization Details
  const fetchOrganizationData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenant_id = (userDetails && userDetails[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userDetails[0].user.tenants.length > 0 ? userDetails[0].user.tenants[0].id : null;
      const tenantURL:string = `${BASE_URL}/auth-service/tenants/?page=1&limit=100`;
      const response = await axios.get(tenantURL, { headers });
      let organizationsList = response.data.tenants;
      if(tenant_id !== '*'){
        organizationsList = organizationsList.filter((item:any) => (item.id === tenant_id))
      }
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = organizationsList.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setOptions(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching industry data:', error);
    }
  };


  

  const fetchNetworkData = async (tenantId: string) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const networkURL:string = `${BASE_URL}/asset-service/tenants/${tenantId}/networks/?page=1&limit=10`;
      try {
        const response = await axios.get(networkURL, {
          headers
        });
        const networkList = response.data.networks;
        const dropdownOptions: DropdownOption[] = networkList.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
  
        setNetworks(dropdownOptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }

const handleOrgChange=()=>{

}
  const handleSelectElement = (tenantId:string) => {
    //based on the tenantId need to load the networks
    fetchNetworkData(tenantId).then(()=>{
      setValue('networkName',deviceDetailsInfo && deviceDetailsInfo.networks[0].id);
    });
    fetchSupervisorsData(tenantId).then(()=>{
      // setValue('supervisor',data?.network?.id);
    });
  };
  const handleNetworkElement = (e: any) => {
    console.log(e,">>>>>>>>>>>>>>")
    setNetworkId(e.value);
    setValue('networkName',e.value);

  };

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onSubmit = async (newValues: {
    name: string, organizationName: string, networkName: string, macAddress: string, email: string, ownerFirstName: string, ownerLastName: string, ownerPhone: string, supervisor: string, department: string, employeeId: string, user_id:string
  }) => {
    if (newValues.networkName !== '' && newValues.organizationName !== '' && newValues.macAddress !== '' &&
      newValues.email !== '' && newValues.ownerFirstName !== '' && newValues.ownerLastName !== '' && newValues.name !== '') {
        newValues.name = capitalizeFirstLetter(newValues.name);
        if(userExist){
          newValues.user_id = userExistData !== null ? userExistData.user_id : data.user_id;
          const responseData: any = await editDevice(newValues);
          if (!responseData.status) {
            setErrorMessage(responseData.message);
            setSuccessMessage(null);
            return false;
          } else {
            // setSuccessMessage(responseData.message);
            setErrorMessage(null);
            if (undefined !== responseData.data)
            closeModal(); setModalOpen(false); reset();
              onEdit({status:true, message:responseData.message});
            return false;
          }
        }else{
          const userDataOrganization: any = await createUserForNewDevice(newValues.organizationName, newValues);
          if (undefined !== userDataOrganization.response && userDataOrganization.response.data.error) {
            setErrorMessage(userDataOrganization.response.data.error.message);
            setSuccessMessage(null);
            return false;
          } else {
              setValue('ownerFirstName', userDataOrganization.data.user.meta_data.first_name)
              setValue('ownerLastName', userDataOrganization.data.user.meta_data.last_name)
              setUserExistData(userDataOrganization.data.user);
              setUserExist(true);
            newValues.user_id = userDataOrganization.data.user.id;
            const responseData: any = await editDevice(newValues);
          if (!responseData.status) {
            setErrorMessage(responseData.message);
            setSuccessMessage(null);
            return false;
          } else {
            // setSuccessMessage(responseData.message);
            setErrorMessage(null);
            if (undefined !== responseData.data)
            closeModal(); setModalOpen(false); reset();
              onEdit({status:true, message:responseData.message});
            return false;
          }

          }
        }
    } else {
      setErrorMessage(null);
setSuccessMessage(null);
    }
  
  };
  const createUserForNewDevice = async (tenant_id: any, formData: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const New_Organization_MAP_TO_USER_URL: string = `${BASE_URL}/auth-service/tenants/${tenant_id}/users/`;

      let userOrganization = {
        first_name: formData.ownerFirstName,
        last_name: formData.ownerLastName,
        email: formData.email,
        roles: [process.env.REACT_APP_DEVICE_OWNER], //temporary role hardcoded
        phone: formData.ownerPhone
      }
      const response = await axios.post(New_Organization_MAP_TO_USER_URL, userOrganization, { headers });
      return response;
    } catch (error) {
      return error;
    }

  }
  
   const editDevice = async (formState:any) => {
      try{
       
      //  return false;
      const token = userData?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        
      const tenantId = data?.tenant.id;
      const deviceId = data?.id;
      
      let editDevice = {
        id : deviceId,
        networks:[formState?.networkName],
        name: formState?.name,
        // description: formState.description,
        mac_addresses:[{label:"DEFAULT",mac_address:formState?.macAddress}],
        // mac_address:formState?.macAddress,
        user_id:formState.user_id
            }
      
     const baseURL = `${BASE_URL}/asset-service/tenants/${tenantId}/devices/${deviceId}/`;
    //  return false;
      const response = await axios.put(baseURL,editDevice,{headers});
// return false;
      return { status: true, message: 'Device updated successfully', data: response }
     }catch(error:any){
      return { status: false, message: error.response.data.error.message, data: {} }
     }
      
      
    };
   
    const handleDelete = (deviceId : any) => {
      onDelete(deviceId);
    }
  
    const fetchSupervisorsData = async (tenantId: string) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const supervisorURL = BASE_URL + `/auth-service/tenants/${tenantId}/users/`;
      try {
        const response = await axios.get(supervisorURL, {
          headers
        });
        const supervisorList = response.data.users;
        const dropdownOptions: DropdownOption[] = supervisorList.map((item: any) => ({
          value: item.id,
          label: item.display_name,
        }));
        setSupervisor(dropdownOptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const handleEmailChange = async (emailId:string) => {
    if(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(emailId)){
      try {
        const token = userData?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        const tenantId = data?.tenant.id;
        const supervisorURL =  `${BASE_URL}/auth-service/tenants/${tenantId}/users/?email=${emailId}`;
        try {
          const response = await axios.get(supervisorURL, {
            headers
          });
          const userList = response.data.users;
          if(userList.length > 0){
            setValue('ownerFirstName',userList[0].meta_data.first_name)
            setValue('ownerLastName',userList[0].meta_data.last_name)
            setUserExist(true)
            setUserExistData(userList[0]);
            setUserExistWithOtherOrg(false);
          }else {
            const supervisorURL =  `${BASE_URL}/auth-service/tenants/*/users/?email=${emailId}`;
            try {
              const response = await axios.get(supervisorURL, {
                headers
              });
              const userList = response.data.users;
              if(userList.length > 0){
                setValue('ownerFirstName', userList[0].meta_data.first_name)
                setValue('ownerLastName', userList[0].meta_data.last_name)
                setUserExistData(userList[0]);
                setUserExist(true)
                setUserExistWithOtherOrg(true);
                setErrorMessage('Email id already in use, please choose some other email id');
                setSuccessMessage(null);
              }else{
                delete errors["email"];
                setUserExistData(null);
                setUserExist(false)
                setValue('ownerFirstName',"")
                setValue('ownerLastName',"")
                setUserExistWithOtherOrg(false);
                setErrorMessage(null);
                setSuccessMessage(null);
              }
            
            }catch (error) {
              console.error('Error fetching data:', error);
              setUserExistData(null);
              setUserExist(false);
              setUserExistWithOtherOrg(false);
              setValue('ownerFirstName',"")
              setValue('ownerLastName',"")
            }
            
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setUserExist(false)
          setUserExistData(null);
          setUserExistWithOtherOrg(false);
          setValue('ownerFirstName',"")
          setValue('ownerLastName',"")
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
        setUserExistData(null);
        setValue('ownerFirstName',"")
        setUserExistWithOtherOrg(false);
        setValue('ownerLastName',"")
      }
    }else{
      setUserExist(false)
      setUserExistWithOtherOrg(false);
      setUserExistData(null);
      setValue('ownerFirstName',"")
      setValue('ownerLastName',"")
    }
    
  }

    return (
        <div>
           <Modal dialogClassName='edit-device-dialog'  backdrop="static" show={modalOpen} onHide={() => { reset(); closeModal(); setModalOpen(false); }} >
         <div>
         
             <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel"><h3 className="titelcolostyle deviceHeader">
               <FontAwesomeIcon icon={faLaptop as IconProp} />
                 <span className="deviceHeaderTxt">Devices</span>
                 </h3></h5>
                 <button type="button" className="btn-close" aria-label="Close"
                onClick={() => {
                  setErrorMessage(null); setSuccessMessage(null);setEditForm(true);closeModal();setModalOpen(false);
                }}></button>
             </div>
           
             <form className="edit-device-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                {null !== errorMessage &&
                  <div>
                    <p style={{ color: 'red' }}>Error: {errorMessage}</p>
                  </div>
                }
                {
                  null !== successMessage &&
                  <div>
                    <p style={{ color: 'blue' }}>Success: {successMessage}</p>
                  </div>
                }
                </div>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    {/* <form className="create-organization-form" onSubmit={handleSubmit(onSubmit)}> */}
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label" htmlFor="organizationName">Organization<span className="require">*</span></label>
                          <Select
                            isDisabled={true}
                            className={`basic-single ${errors.organizationName ? 'is-invalid' : ''} selectFieldFont`}
                            classNamePrefix="select"
                            id="organizationName"
                            {...register('organizationName', {
                              required: true,
                            })}
                            onChange={(e)=>handleOrgChange()}
                             options={options}
                            isSearchable={true}
                            value={data &&
                              options.filter(org =>
                                org.value === data.tenant.id)
                            }
                          />
                          {/* <Select 
                            className={`basic-single selectFieldFont ${errors.organizationName ? 'is-invalid' : ''}`}
                            classNamePrefix="select"
                            id="organizationName"
                            {...register('organizationName', {
                              required: true,
                            })}
                            value = { data.tenant.id }
                           // onChange={(e)=>handleSelectElement(e.target.value)}
                           isDisabled={true}
                          >
                            <option value="">Select an option</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Select> */}
                          {errors.organizationName &&
                            <p className="invalid-feedback d-block">Organization is required.</p>}
                        </div>
                      </div>
                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="label" htmlFor="networkName">Network<span className="require">*</span></label>
                          <Select
                            isDisabled={editForm}
                            className={`basic-single ${errors.networkName ? 'is-invalid' : ''} selectFieldFont`}
                            classNamePrefix="select"
                            id="networkName"
                            {...register('networkName', {
                              required: true,
                            })}
                            onChange={(e)=>handleNetworkElement(e)}
                            options={networks}
                            isSearchable={true}
                            value={deviceDetailsInfo &&
                              networks.filter(network =>
                                network.value === (networkId != '' && !editForm ? networkId : deviceDetailsInfo.networks[0].id))
                            }
                          />
                          {/* <select 
                            className={`form-control ${errors.networkName ? 'is-invalid' : ''}`}
                            id="networkName"
                            {...register('networkName', {
                              required: true,
                            })}
                            value= {networks[0]?.value}
                             disabled={editForm}
                          >
                            <option value="">Select an option</option>
                            {networks.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}  
                          </select> */}
                          {errors.networkName &&
                            <p className="invalid-feedback d-block">Network is required.</p>}
                        </div>
                      </div>

                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Device Name<span className="require">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            id="name"
                            {...register('name', {
                              required: true,
                            })}
                          disabled={editForm}
                          />
                          {errors.name &&
                            <p className="invalid-feedback d-block">Device name is required.</p>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label" htmlFor="name">MAC Address<span className="require">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${errors.macAddress ? 'is-invalid' : ''}`}
                            id="macAddress"
                            {...register('macAddress', {
                              required: true,
                            })}
                            disabled={editForm}
                          />
                          {errors.macAddress &&
                            <p className="invalid-feedback d-block">macAddress is required.</p>}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label">Email ID <span className="require">*</span></label>
                          <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            placeholder="Enter Email Id"
                            {...register('email', {
                              required: true,
                              pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                            })}
                            // value={ data.created_by.email}
                            disabled={editForm}
                            onChange={(e)=>{handleEmailChange(e.target.value)}}
                          />
                          {errors.email && errors.email.type === "required" &&
                            <p className="invalid-feedback d-block">Email ID is required.</p>}
                          {errors.email && errors.email.type === "pattern" &&
                            <p className="invalid-feedback d-block">Enter correct Email ID.</p>}
                        </div>


                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Owner First Name<span className="require">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${errors.ownerFirstName ? 'is-invalid' : ''}`}
                            id="ownerFirstName"
                            {...register('ownerFirstName', {
                              required: true,
                              pattern:/^[a-z]+$/i
                            })}
                            disabled={editForm}
                            // value={data.user.first_name}
                          />
                          {errors.ownerFirstName && errors.ownerFirstName.type === "required" &&
                          <p className="invalid-feedback d-block errorFont">Owner first name is required.</p>}
                        {errors.ownerFirstName && errors.ownerFirstName.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter vallid name.</p>}
                       
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Owner Last Name<span className="require">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${errors.ownerLastName ? 'is-invalid' : ''}`}
                            id="ownerLastName"
                            {...register('ownerLastName', {
                              required: true,
                              pattern:/^[a-z]+$/i
                            })}
                            disabled={editForm}
                            // value={data.user.last_name}
                          />
                          {errors.ownerLastName && errors.ownerLastName.type === "required" &&
                          <p className="invalid-feedback d-block errorFont">Owner last name is required.</p>}
                        {errors.ownerLastName && errors.ownerLastName.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter vallid name.</p>}
                      </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Phone No</label>
                          <input
                            type="number"
                            className="form-control"
                            id="ownerPhone"
                            {...register('ownerPhone')}
                            disabled={editForm}
                          />
                          {errors.ownerPhone && errors.ownerPhone.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter vallid phone number.</p>}

                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Employee ID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="employeeId"
                            {...register('employeeId')}
                            disabled={editForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Department</label>
                          <input
                            type="text"
                            className="form-control"
                            id="department"
                            {...register('department')}
                            disabled={editForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Supervisor Name
                          {/* <span className="require">*</span> */}
                          </label>
                          <Select
                            isDisabled={editForm}
                            className={`basic-single ${errors.supervisor ? 'is-invalid' : ''} selectFieldFont`}
                            classNamePrefix="select"
                            id="supervisor"
                            {...register('supervisor', {
                            })}
                            onChange={(e:any)=>console.log(e)}
                             options={supervisors}
                            isSearchable={true}
                          />
                          
                          {errors.supervisor &&
                            <p className="invalid-feedback d-block">Supervisor is required.</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              {
                editForm &&
                <>
                   <button type="button" className="btn btn-danger btn-sm"
                    disabled={!editForm} onClick={() => displayDeleteModal()}
                  >Delete</button>
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => setEditForm(false)}
                    disabled={!editForm}>Edit </button>
                </>
              }


              {
                !editForm &&
                <>
                  <button type="submit" className="btn btn-secondary btn-sm">Save</button>
                  <button type="button" className="btn btn-light btn-sm" onClick=
                    {() => { setErrorMessage(null); setSuccessMessage(null); setEditForm(true); resetEdit(); }} data-bs-dismiss="modal"
                  >Cancel </button>
                </>
              }
              </div>
            </form>
          
         </div>

        { showDeleteModal && 
         <DeleteDevice data={data} onDelete={onDelete} userData={userData} isModalOpen={showDeleteModal} closeModal={closeModal} closeDeleteModal={closeDeleteModal}  />
        }
         
         </Modal>
     </div>
    )
}

export default EditDevice;