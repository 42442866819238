import React, {useEffect,useState} from 'react';

interface paginationProps {
    pageChangeHandler : any ,
    totalRows:any,
    rowsPerPage:any,
    setFilter:any,
    currentPageNumber:number
  }
  
const Pagination:React.FC<paginationProps> = ({ pageChangeHandler, totalRows, rowsPerPage,setFilter,currentPageNumber}) => {
    // Calculating max number of pages
    const noOfPages = Math.ceil(totalRows / rowsPerPage);
    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(noOfPages)];
    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
    const [currentPage, setCurrentPage] = useState(currentPageNumber);
  
    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);
  
    // Onclick handlers for the butons
    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    // const onPageSelect = (pageNo) => setCurrentPage(pageNo);
  
    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
      if(noOfPages === 0){
        setCanGoNext(false);
        setCanGoBack(false);
      }else{
        if (noOfPages === currentPage) {
        
          setCanGoNext(false);
        } else {
          
          setCanGoNext(true);
        }
        if (currentPage === 1) {
          setCanGoBack(false);
        } else {
          setCanGoBack(true);
        }
      }
    }, [noOfPages,currentPageNumber]);
  
    // To set the starting index of the page
    useEffect(() => {
      const skipFactor = (currentPage - 1) * rowsPerPage;
      // Some APIs require skip for paginaiton. If needed use that instead
      // pageChangeHandler(skipFactor);
      pageChangeHandler(currentPage);
    }, [currentPage]);
  
    return (
      <>
       
        {noOfPages >= 0 ? (
          <div className="pagination" >
            {/* <div>
            <select
                value={rowsPerPage}
                // onChange={(e) => {
                //   setrecordPerPage(Number(e.target.value));setCurrentPage(1);
                // }}
                style={{width:50, alignSelf:"flex-start"}}
                // className="pagination"
              >
                {[25, 50, 75, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div> */}
          <div>
          {/* <button onClick={() => setFilter('name', '')} className="btn btn-secondary  marg-right-3">
            Clear Filter
          </button> */}
          <button onClick={onPrevPage}
                disabled={!canGoBack} className="btn btn-secondary btn-sm marg-right-3">
            Previous
          </button>
          <span className='marg-right-3'>
            Page{' '}
            <strong>
              {noOfPages > 0 ? currentPage : 0} of {noOfPages}
            </strong>{' '}
          </span>
          
          <button onClick={onNextPage}
                disabled={!canGoNext} className="btn btn-secondary btn-sm ">
            Next
          </button>
          </div>
         
        </div>
        ) : null}
      </>
    );
  };
  export default Pagination;