import { Network } from "../../interface/network";
import { useState,useEffect } from 'react';
import axios from "axios";
import { Modal } from "react-bootstrap";
import './deleteNetwork.css';


interface DeleteNetworkModalProps {
    data:Network | null;
    onDelete:(message:any) => void;
    userData:any;
    isModalOpen:boolean;
    closeModal:any;
    closeDeleteModal:any;
}






const DeleteNetwork: React.FC<DeleteNetworkModalProps> = ({data,onDelete,userData,isModalOpen,closeModal,closeDeleteModal}) => {
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(isModalOpen);

  useEffect(() => {
    setDeleteModalOpen(isModalOpen);
  }, [isModalOpen]);

    const handleDelete = async (network: Network | null) => {
        try {
          const token = userData?.access_token;
          const authToken = `Bearer ${token}`;
          const headers = {
            Authorization: authToken,
          };
          const tenantId = network?.tenant.id;
          const networkId = network?.id;
          const deleteNetworkURL:string = `${BASE_URL}/asset-service/tenants/${tenantId}/networks/${networkId}`;
    
          const response = await axios.delete(deleteNetworkURL,{headers});
        onDelete({status:true, message:"Network deleted successfully"});
        } catch (error:any) {
          console.error('Error deleting network:', error);
          return false;
          
        }
      };
    
    return (
        <div>
           <Modal dialogClassName='delete-network-dialog' backdrop="static" show={deleteModalOpen} onHide={() => { closeDeleteModal(); setDeleteModalOpen(false) }}>
        <div>
         
            <div className="modal-header delete-header">
              <h5  id="exampleModalLabel">
                <h3 className="titelcolostyle"> Confirm Deletion</h3></h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <button type="button" className="btn-close" onClick={() => {closeDeleteModal(); setDeleteModalOpen(false) }} aria-label="Close"></button>
            </div>
            <div className="modal-body delete-body">
            <h2 className="modal-body delete-network-text">Are you sure you want to delete this network?</h2>        
            </div>
            <div className="modal-footer delete-footer">
                <button type="button" className="btn btn-danger"
                   onClick={() => { handleDelete(data); closeDeleteModal();setDeleteModalOpen(false);closeModal();}}  >Yes</button>
                <button type="button" className="btn btn-light btn-sm" onClick={() => {closeDeleteModal(); setDeleteModalOpen(false) }}>No</button>
            </div>
           
         
        </div>
        </Modal>
      </div>
    );
  };
  
  export default DeleteNetwork;

