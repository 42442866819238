import { Organization } from '../../interface/organization';
import orgicon from '../../images/orgicon.svg';
import { useState, ChangeEvent, useEffect, SetStateAction } from 'react';
import './createOrganization.css';
import { Modal } from "react-bootstrap";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { UserDetails } from '../../interface/userDetails';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseLaptop  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { error } from 'console';

interface createOrganizationProps {
  onSave: () => void;
  userData: any;
  onDataUpdate:(userDetails:any)=> void;
  isAddOpen: boolean;
  closeAddModal: any;
}

interface FormState {
  name: string;
  country: string;
  industry: string;
  state: string;
}
interface DropdownOption {
  value: string;
  label: string;
}



const CreateOrganization: React.FC<createOrganizationProps> = ({ onSave, userData,onDataUpdate, isAddOpen, closeAddModal }) => {

  const { register, handleSubmit, reset,setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      industry: '', country: '', state: '', email: '', city: '', firstName: '', lastName: '', mobileNumber: '', addressLine1: '',
      addressLine2: '', zipCode: ''
    }
  });
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [stateValue, setStateValue] = useState<string>('');
  const [newOrganization, setNewOrganization] = useState<Organization>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [countryList, setCountryList] = useState<DropdownOption[]>([]);
  const [stateList, setStateList] = useState<DropdownOption[]>([]);
  const [modalOpen, setModalOpen] = useState(isAddOpen);
  const [userExist, setUserExist] = useState(true)
  useEffect(() => {
    // setModalOpen(isAddOpen);
  }, [isAddOpen]);
  
  /*
  submit new Organization with email
  */
  const onSubmit = async (data: {
    name: string, industry: string, state: string, country: string, email: string
    city: string, firstName: string, lastName: string, mobileNumber: string, addressLine1: string, addressLine2: string,
    zipCode: string
  }) => {
    if (data.name !== ''  && data.state !== '' && data.country !== '' &&
      data.email !== '' && data.city !== '' && data.firstName !== '' && data.lastName !== ''
      && data.mobileNumber !== '' && data.addressLine1 !== '' && data.addressLine2 !== '' &&
      data.zipCode !== '') {
        if(!userExist){
          const responseData: any = await createNewOrganization(data);

          //create new User Map with new Organization
          if (undefined !== responseData.response && responseData.response.data.error) {
            setErrorMessage(responseData.response.data.error.message);
            setSuccessMessage(null);
            return false;
          } else {
            const userDataOrganization: any = await createUserForNewOrganization(responseData.data.tenant_id, data);
            if (undefined !== userDataOrganization.response && userDataOrganization.response.data.error) {
              setErrorMessage(userDataOrganization.response.data.error.message);
              setSuccessMessage(null);
              return false;
            } else{
              setSuccessMessage("New organization is created and user is associated");
              setErrorMessage(null);
              // onDataUpdate(userDataOrganization);
              onSave();
              closeAddModal();
              return false;
            }
           
          }
        }else{
          setErrorMessage('Email id already in use, please choose some other email id');
          setSuccessMessage(null);
          return false;
        }
      
    } else {
      setErrorMessage(null);
    }

  };

  const createNewOrganization = async (formData: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const create_organization_URL:string = `${BASE_URL}/auth-service/tenants/`;

      let tenant = {
        name: formData.name,
        industry: formData.industry,
        country: formData.country,
        state: formData.state,
        address_line_1: formData.addressLine1,
        address_line_2: formData.addressLine2,
        zipcode: formData.zipCode,
        city: formData.city,
        phone:formData.mobileNumber
      }
      const response = await axios.post(create_organization_URL, tenant, { headers });
      return response;
    }
    catch (error) {
      return error;
    }

  }




  const createUserForNewOrganization = async (tenant_id: any, formData: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const New_Organization_MAP_TO_USER_URL:string = `${BASE_URL}/auth-service/tenants/${tenant_id}/users/`;

      let userOrganization = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        roles: [process.env.REACT_APP_ORG_ADMIN], //temporary role hardcoded
        phone: formData.mobileNumber
      }
      const response = await axios.post(New_Organization_MAP_TO_USER_URL, userOrganization, { headers });
      return response;
    } catch (error) {
      return error;
    }

  }

  // // const handleSelectElement = (e: ChangeEvent<HTMLSelectElement>) => {
  // //   setSelectedValue(e.target.value);
  // // };

  const handleSelectCountry = (countryCode:any) => {
    fetchStateList(countryCode.target.value);
   setValue('country',countryCode.target.value);
  setSelectedValue(countryCode.target.value);
    
  };


  const handleSelectState = (selectedState:any) => {
    setValue('state',selectedState.target.value)
    setStateValue(selectedState.target.value)
  };
  useEffect(() => {
    fetchIndustryData();
    countryData();
  },[isAddOpen]);

  const fetchIndustryData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantURL = BASE_URL + `/auth-service/industries/`;
      const response = await axios.get(tenantURL, { headers });
      const industryList = response.data.industries;
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = industryList.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching industry data:', error);
    }
  };

  

  const countryData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantURL =   `${BASE_URL}/asset-service/resources/countries/`;
      const response = await axios.get(tenantURL, { headers });
      const countryList = response.data.countries;
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = countryList.map((item: any) => ({
        value: item.iso_code_2,
        label: item.name,
      }));
      setCountryList(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching country data:', error);
    }
  }

  const fetchStateList = async (countryCode:string) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantURL =   `${BASE_URL}/asset-service/resources/states/?country_code=${countryCode}`;
      const response = await axios.get(tenantURL, { headers });
      const stateListData = response.data.states;
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = stateListData.map((item: any) => ({
        value: item.code,
        label: item.name,
      }));

      setStateList(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching state list data:', error);
    }
  }

  const checkSpecialChar =(e:any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
   };
   const handleEmailChange = async (emailId:string) => {
    if(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(emailId)){
      try {
        const token = userData?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        const supervisorURL =  `${BASE_URL}/auth-service/tenants/*/users/?email=${emailId}`;
        try {
          const response = await axios.get(supervisorURL, {
            headers
          });
          const userList = response.data.users;
          if(userList.length > 0){
            // Object.assign(errors, { email:{type:'alreadyInUse',ref: 'input.form-control.is-invalid'} });
            setUserExist(true)
            setValue('firstName', userList[0].meta_data.first_name)
            setValue('lastName', userList[0].meta_data.last_name)
            setErrorMessage('Email id already in use, please choose some other email id');
            setSuccessMessage(null);
          }else{
            // delete errors["email"];
            setUserExist(false)
            setErrorMessage(null);
            setSuccessMessage(null);
          }
         
      }catch (error) {
        // delete errors["email"];
        // setUserExist(false)
        console.error('Error fetching data:', error);
        setUserExist(false);
        setErrorMessage(null);
        setSuccessMessage(null);
      }
    }catch(error){
      // delete errors["email"];
      //   setUserExist(false)
      console.error('Error fetching data:', error);
      setUserExist(false);
      setErrorMessage(null);
      setSuccessMessage(null);
    }
    
  }
    } 



  return (
      <div>
         <Modal backdrop="static" show={modalOpen}>
          <div className="modal-header">
            <div className="modal-title" id="exampleModalLabel"><h3 className="titelcolostyle createOrganizationHeader">
            <FontAwesomeIcon icon={faHouseLaptop as IconProp} />
               <span className="createOrganizationHeaderTxt">Organizations</span>
               </h3></div>
            <button type="button" className="btn-close"
              onClick={() => {
                setErrorMessage(null); setSuccessMessage(null);
                reset(); closeAddModal();
              }}
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {errorMessage !== null &&
              <div>
                <p style={{ color: 'red' }}>Error: {errorMessage}</p>
              </div>
            }
            {
              successMessage !== null &&
              <div>
                <p style={{ color: 'blue' }}>Success: {successMessage}</p>
              </div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Organization Name <span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                      placeholder="Enter Organization name"
                      maxLength={30}
                      {...register('name', {
                        required: true,
                        minLength: 2,
                        maxLength: 30,
                        pattern: /^[\w\-.\s]+$/
                      })}
                      
                    />
                    {errors.name && errors.name.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Name is required.</p>}
                    {errors.name && errors.name.type === "minLength" &&
                      <p className="invalid-feedback d-block errorFont">Name should contain at least 2
                        characters.</p>}
                    {errors.name && errors.name.type === "maxLength" &&
                      <p className="invalid-feedback d-block errorFont">Maximum 30 characters allowed.</p>}
                    {errors.name && errors.name.type === "pattern" &&
                      <p className="invalid-feedback d-block errorFont">Name should be alphanumeric.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email ID <span className="require">*</span></label>
                    <input
                      // type="email"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      placeholder="Enter Email Id"
                      {...register('email', {
                        required: true,
                        pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                      })}
                      onChange={(e)=>{handleEmailChange(e.target.value)}}
                    />
                    {errors.email && errors.email.type === "required" &&
                      <p key={errors.email && errors.email.type} className="invalid-feedback d-block errorFont">Email ID is required.</p>}
                    {errors.email && errors.email.type === "pattern" &&
                      <p key={errors.email && errors.email.type} className="invalid-feedback d-block errorFont">Enter correct Email ID.</p>}
                      {errors.email && errors.email.type === "alreadyInUse" &&
                      <p key={errors.email && errors.email.type} className="invalid-feedback d-block errorFont">Email id already in use</p>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name: <span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                      placeholder="Enter First Name"
                      {...register('firstName', {
                        required: true
                      })}
                      onKeyDown={(e:any)=>checkSpecialChar(e)}
                    />
                    {errors.firstName && errors.firstName.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">First Name is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name: <span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                      placeholder="Enter LastName"
                      {...register('lastName', {
                        required: true
                      })}
                      onKeyDown={(e:any)=>checkSpecialChar(e)}
                    />
                    {errors.lastName && errors.lastName.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Last Name is required.</p>}
                  </div>
                </div>
              </div>
              <div className='row'>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address 1: <span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.addressLine1 ? 'is-invalid' : ''}`}
                      placeholder="Enter Address 1"
                      {...register('addressLine1', {
                        required: true
                      })}
                      // onKeyDown={(e:any)=>checkSpecialChar(e)}
                    
                    />
                    {errors.addressLine1 && errors.addressLine1.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Address 1. is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address 2: <span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.addressLine2 ? 'is-invalid' : ''}`}
                      placeholder="Enter Address 2"
                      {...register('addressLine2', {
                        required: true
                      })}
                      // onKeyDown={(e:any)=>checkSpecialChar(e)}
                    />
                    {errors.addressLine2 && errors.addressLine2.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Address 2. is required.</p>}
                  </div>
                </div>
              </div>
              <div className='row'>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country <span className="require">*</span></label>
                    

                        <select

                        className={`form-select ${errors.country ? 'is-invalid' : ''}`}
                        value={selectedValue}
                        id="country"
                        {...register('country', {
                          required: true
                        })}
                        onChange={(e)=>handleSelectCountry(e)}
                        >
                        <option value="">Select an option</option>
                        {countryList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                        </select>
                    {errors.country && 
                      <p className="invalid-feedback d-block errorFont">Country is required.</p>}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>State <span className="require">*</span></label>
                    <select
                        className={`form-select ${errors.state ? 'is-invalid' : ''}`}
                        value={stateValue}
                          id="state"
                          {...register('state', {
                            required: true
                          })}
                          onChange={(e)=>handleSelectState(e)}
                          >
                          <option value="">Select an option</option>
                          {stateList.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    {errors.state  &&
                      <p className="invalid-feedback d-block errorFont">State is required.</p>}
                  </div>
                </div>

              </div>
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City <span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                      placeholder="Enter City"
                      {...register('city', {
                        required: true
                      })}
                      // onKeyDown={(e:any)=>checkSpecialChar(e)}
                    />
                    {errors.city && errors.city.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">City is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ZipCode: <span className="require">*</span></label>
                    <input
                      type="number"
                      className={`form-control ${errors.zipCode ? 'is-invalid' : ''}`}
                      placeholder="Enter Zip Code"
                      {...register('zipCode', {
                        required: true
                      })}
                    />
                    {errors.zipCode && errors.zipCode.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">ZipCode  is required.</p>}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone No: <span className="require">*</span></label>
                    <input
                      type="number"
                      className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
                      placeholder="Enter Phone Number"
                      {...register('mobileNumber', {
                        required: true
                      })}
                    />
                    {errors.mobileNumber && errors.mobileNumber.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Phone No. is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Industry Name <span className="require">*</span></label>
                    

                      <select

                          className={`form-select ${errors.industry ? 'is-invalid' : ''}`}
                          id="industry"
                          {...register('industry', {
                            required: true
                          })}
                          // onChange={(e)=>handleSelectIndustry(e)}
                          >
                          <option value="">Select an option</option>
                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>

                      {errors.industry && errors.industry.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Industry is required.</p>}
                    
                  </div>
                </div>


              </div>

              <div className="row">
                <h4 className="mt-3 mb-2">Integrations</h4>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Provider <span className="require">*</span></label>
                    <select
                      className="form-select"
                      id="integration_provider">
                        <option value="">Select an option</option>
                        <option value="CROWDSTRIKE">CrowdStrike</option>
                        <option value="FORESCOUT">Forescout</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Integration Provider <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-select"
                      id="provider_config_api_key" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sync Frequency<span className="require">*</span></label>
                    <select
                      className="form-select"
                      id="integration_provider">
                        <option value="">Select an option</option>
                        <option value="CROWDSTRIKE">Every 15 mins</option>
                        <option value="FORESCOUT">Every 30 mins</option>
                        <option value="FORESCOUT">Every Hour</option>
                        <option value="FORESCOUT">Every 3 Hours</option>
                        <option value="FORESCOUT">Every 6 Hours</option>
                        <option value="FORESCOUT">Every 12 Hours</option>
                        <option value="FORESCOUT">Every Day</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div className="modal-footer">
                <button type="button" className="btn btn-light btn-sm " data-bs-dismiss="modal" onClick={() => { reset(); setErrorMessage(null); closeAddModal(); }}>Close</button>
                <button type="submit" className="btn btn-secondary btn-sm"
                >Save </button>

              </div>
            </form>
          </div> 
      </Modal>
    </div>
  )
}

export default CreateOrganization;