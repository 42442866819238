import React, { useEffect, useState } from 'react';
import './organizationTable.css';
import { useTable, useSortBy, usePagination, useFilters, Column } from 'react-table';
import axios from "axios";
import upImage from '../../images/up.png';  // Import the image
import networksicon from '../../images/down.png';
import addorg from '../../images/Group 8.svg';
//import tenantlist from  '../../json/tenantlist.json';
import EditOrganization from './editOrganization';
import { Organization } from '../../interface/organization';
import CreateOrganization from './createOrganization';
import moment from 'moment';

import orgmainicon from '../../images/orgicon.svg';
import { UserDetails } from '../../interface/userDetails';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Pagination from '../utility/pagination';
import {Circles} from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseLaptop  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


const columns: Column<Organization>[] = [

  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy:false,
  },
  {
    Header: 'Network Count',
    accessor: 'network_count',
    disableSortBy:true,
  },
  {
    Header: 'Device Count',
    accessor: 'device_count',
    disableSortBy:true,
  },
  // {
  //   Header: 'Created By',
  //   accessor: (row: Organization) => row.created_by?.display_name || '',
  // },
  {
    Header: 'Created On',
    accessor: 'created_on',
    disableSortBy:false,
  },
  {
    Header: 'Modified On',
    accessor: 'modified_on',
    disableSortBy:false,
  },


];

interface TenantTableProps {
  data: any;
}

const TenantTable: React.FC<TenantTableProps> = ({ data }) => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordPerPage, setrecordPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal,setShowModal] = useState(false);
  const [showAddModal,setShowAddModal] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [isFilter, setIsFilter] = useState(false);


  const displayAddModal = () => {
    setShowAddModal(true);
  }
  const closeAddModal = () => {
    setShowAddModal(false);
  }

 const displayModal = () => {
    setShowModal(true);
  }
  const closeModal = () => {
    setShowModal(false);
    setTenantDetails({})
    // setSelectedTenant({
    //   id: '',
    //   industry: {
    //     id: '',
    //     name: '',
    //     config: '',
    //     is_enabled: false,
    //     created_on: '',
    //     modified_on: '',
    //     created_by: '',
    //     modified_by: ''
    //   },
    //   device_count: '',
    //   network_count: '',
    //   name: '',
    //   description: '',
    //   country: '',
    //   state: '',
    //   address_line_1: '',
    //   address_line_2: '',
    //   addon_data: '',
    //   config: '',
    //   is_enabled: false,
    //   created_on: '',
    //   modified_on: '',
    //   user: {
    //     id: '',
    //     email: '',
    //     first_name: '',
    //     last_name: '',
    //     display_name: ''
    //   },
    //   created_by: {
    //     id: '',
    //     email: '',
    //     first_name: '',
    //     last_name: '',
    //     display_name: ''
    //   },
    //   modified_by: {
    //     id: '',
    //     email: '',
    //     first_name: '',
    //     last_name: '',
    //     display_name: ''
    //   },
    //   city: '',
    //   zipcode: '',
    //   stats: {
    //     device: {
    //       total: '',
    //     },
    //     network: {
    //       total: '',
    //     }
    //   }
    // });
    
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    
  } = useTable<any>(
    {
      columns,
      data: tableData,
    },
    useFilters,
    useSortBy,
    usePagination,
    
  );

  const [selectedTenant, setSelectedTenant] = useState([]);
  const [tenantDetails, setTenantDetails] = useState({});
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [userOrganizationData, setUserOrganizationData] = useState([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleChildComponentData = (organizationData: any) => {
    setUserOrganizationData(organizationData);
  }

  const [sortField, setSortField] = useState('created_on');
  const [sortOrder, setSortOrder] = useState('DESC');


  // const handleDelete = (tenantId: any) => {
  //   setTableData((prevData: any[]) => prevData.filter((organization) => organization.id !== tenantId));
  // }


  const fetchData = async (searchString: string = '',sortingField: string = '', sortingType: string = '') => {
    setLoading(true)
    try {
      const token = data?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
     
      const tenantURL: string = `${BASE_URL}/auth-service/tenants/?k=${searchString}&page=${currentPage}&limit=${recordPerPage}&sort_field=${sortingField}&sort_order=${sortingType}`;
      const response = await axios.get(tenantURL, { headers });
      const tenants = response.data.tenants;
      setTotalRecords(response.data.total_records);
      const formattedTenants = tenants.map((tenant: any) => ({
        id: tenant.id,
        name: tenant.name,
        industry: tenant.industry,
        description: tenant.description,
        country: tenant.country,
        state: tenant.state,
        city: tenant.city,
        zipcode: tenant.zipcode,
        device_count: (undefined !== tenant.stats && undefined !== tenant.stats.device) ? tenant.stats.device.total : '0',
        network_count: (undefined !== tenant.stats && undefined !== tenant.stats.network) ? tenant.stats.network.total : '0',
        address_line_1: tenant.address_line_1,
        address_line_2: tenant.address_line_2,
        addon_data: tenant.addon_data,
        config: tenant.config,
        is_enabled: tenant.is_enabled,
        created_on: moment.unix(parseInt(tenant.created_on)).format('YYYY-MM-DD'),
        modified_on: moment.unix(parseInt(tenant.modified_on,)).format('YYYY-MM-DD'),
        created_by: tenant.created_by,
        modified_by: tenant.modified_by,
        user: tenant.user
      }));

      setTableData(formattedTenants);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(searchStr,sortField,sortOrder);
  }, [recordPerPage,currentPage]);

  const handleSave = () => {
    fetchData(searchStr,sortField,sortOrder);
  };

  const handleEdit = (message:any) => {
    fetchData(searchStr,sortField,sortOrder);
    setSuccessMessage(message.message);
    setTimeout(()=>{
      setSuccessMessage(null);
    },10000)
  };

  const handleDelete = (message:any) => {
    fetchData(searchStr,sortField,sortOrder);
    setSuccessMessage(message.message);
    setTimeout(()=>{
      setSuccessMessage(null);
    },10000)
  };


  const fetchTenantDetails = async (tenantDetails:any) => {

    try {
    const token = data?.access_token;
    const authToken = `Bearer ${token}`;
    const headers = {
      Authorization: authToken,
    };
    const tenantDetailsURL:string = `${BASE_URL}/auth-service/tenants/${tenantDetails.id}/`;
    const response = await axios.get(tenantDetailsURL, { headers });
    setTenantDetails(response.data.tenant);
    

  } catch (error: any) {
    return { status: false, message: error.response.data.error.message, data: {} }
  }
    
  }

  const handleSorting = (data:any, sort:string) => {

    let sortFieldString = ''
    if(data.id === 'name'){
      sortFieldString = 'name';
    }else if(data.id === 'created_on'){
      sortFieldString= 'created_on';
    }else if(data.id === 'modified_on'){
      sortFieldString= 'modified_on';
    }else{
      sortFieldString= data.id;
    }
    if(sortFieldString !== '' && !data.disableSortBy){
      setSortField(sortFieldString);
      setSortOrder(sort);
      fetchData(searchStr,sortFieldString,sort);
    }
    
  }
  return (
    <div className="bg-box p-3">
      <div className="App">
        <h3 className='titelcolostyle'>
          {/* <img src={orgmainicon} alt='' /> */}
          <FontAwesomeIcon icon={faHouseLaptop as IconProp} />
           <span className='organizationHeader'>Organizations</span>
           </h3>
        <div className='header-fuctions'>

          <div><input
            type="text"
            placeholder="Search Name"
            onChange={(e) => {(e.target.value.length >= 3 || e.target.value.length === 0) && fetchData(e.target.value,sortField,sortOrder); setIsFilter((e.target.value.length > 0) ? true : false); setSearchStr(e.target.value)}}
            className="form-control mb-3 cutomesearchbox"
          />

          </div>
          <div>
            {/* <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModalOrganization"
              className='addbtn'
              
            >
              Add Organizations

            </button> */}

          <button
               onClick={()=>displayAddModal()}
              className='addbtn'>
              Add Organizations

            </button>
          </div>
          
        </div>
        {
          null !== successMessage &&
          <div>
            <p style={{ color: 'blue' }}>Success: {successMessage}</p>
          </div>
        }
        <div className="scrollable-table-test" style={{justifyContent:"center"}}>
        {loading ? 
        <div style={{marginTop:"100"}}>
          <Circles color="#7C7AFD" height={50} width={50} wrapperStyle={{justifyContent:"center",marginTop:"150px"}}  />
        </div>
       : 
        <table {...getTableProps()} className="table ">
            <thead className='sticky-top'>
              {headerGroups.map((headerGroup, ind) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column,colInd) => (

                    <th key={colInd+"_ind_"+ind} onClick={()=>handleSorting(column, sortOrder === 'DESC'?'ASC':'DESC')}>
                    {column.render('Header')}
                    {
                      !column.disableSortBy && 
                      <span>
                      {
                        
                        sortOrder === 'DESC' ? (
                          <img src={upImage}  className='icon-left-m' alt="Down Arrow" onClick={()=>handleSorting(column,'ASC')} />

                        ) : (
                          <img src={networksicon} className='icon-left-m' alt="Up Arrow" onClick={()=>handleSorting(column,'DESC')} />
                        )
                      }
                      
                    </span>
                    }
                    
                  </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>

              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                    <td>
                      <div className='list-action'>
                        <button
                          className="btn btn-secondary  btn-sm mr-2" 
                          onClick={() => { 
                            setSelectedTenant(row.original);
                            // userInfo(row.original);
                            fetchTenantDetails(row.original);
                            displayModal();
                          }}
                        >
                          View
                        </button>
                      </div>
                    </td>

                  </tr>
                );
              })}
            </tbody>
        </table>
      }

          
        </div>
        <div className="paginationOuterDiv">
          <div style={{ width: "35%" }}>
            <span>Select # of Rows  &nbsp;</span>
            <select
              value={recordPerPage}
              onChange={(e) => {
                setrecordPerPage(Number(e.target.value)); setCurrentPage(1);
              }}
              style={{ width: 50, marginTop: 10, textAlign:"center"  }}
            >
              {[25, 50, 75, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "65%" }}>
            <Pagination key={currentPage}
              totalRows={totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={recordPerPage}
              setFilter={setIsFilter}
              currentPageNumber={currentPage}
            />
          </div>
        </div>
        <>
        {showAddModal && 
        <CreateOrganization key={"createOrganizationKey_"+showAddModal} onSave={handleSave} userData={data} onDataUpdate={handleChildComponentData} isAddOpen={showAddModal} closeAddModal={closeAddModal} />
        }
        
        </>
        {
          (Object.keys(tenantDetails).length > 0  &&  showModal)  && 
          <>
          <EditOrganization key={"editOrganizationKey_"+showModal} onDelete={handleDelete} onEdit={handleEdit} userData={data} tenant={tenantDetails} isOpen={showModal} closeModal={closeModal} />
          </>
        }
      </div>
    </div>

  );
}

export default TenantTable;
