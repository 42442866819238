import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './dashboardTable.css';
import { NavLink } from "react-router-dom";
import { AggregateStats } from '../../interface/aggregateStats';
import { Notification } from '../../interface/notification';
import { Circles } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import {timestampToDaysHrsMin} from '../utility/helperFunction';
import moment from 'moment';

interface DashBoardTableProps {
    data: any;
}


const DashBoardTable: React.FC<DashBoardTableProps> = ({ data }) => {
    const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
    const userData = useSelector((state: any) => state.userReducer.userData);
    // device: {
    //     total: 0,
    //     pending_onboarding: 0,
    //     pending_onboarding_approval: 0,
    //     onboarding_approved: 0,
    //     onboarding_rejected: 0,
    //     onboarded: 0,
    //     authenticated: 0,
    //     rogue: 0,
    //     cloned: 0,
    //     online: 0,
    //     offline: 0,
    //     disabled: 0
    // }
    const [aggregateStats, setAggregateStats] = useState<AggregateStats>({
        tenant: {
            total: 0
        },
        device: {
            total: 0,
            pending_onboarding: 0,
            pending_onboarding_approval: 0,
            onboarding_approved: 0,
            onboarding_rejected: 0,
            onboarded: 0,
            authenticated: 0,
            rogue: 0,
            cloned: 0,
            online: 0,
            offline: 0,
            disabled: 0
        },
        network: {
            total: 0
        }
    });

    const [notification, setNotification] = useState<Notification>({
        items_per_page: 0,
        notifications: [{
            action: "",
            created_by: {
                id: ""
            },
            created_on: 0,
            entity_id: "",
            entity_type: "",
            id: "",
            message: ""
        }],
        total_records: 0,
        trace_id: ""

    });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (userData.length > 0) {
            fetchOrganizationDataCount();
            fetchNotificationDetails();
        }
    }, [userData]);


    const fetchOrganizationDataCount = async () => {
        const token = data?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
            Authorization: authToken,
        };
        // const tenant_id = (userData && userData[0].user.is_superadmin) ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null ;
        const tenant_id = (userData && userData[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null;
        try {
            const config_url: string = `${BASE_URL}/reporting-service/tenants/${tenant_id}/reports/aggregated-stats/`;
            const responseData = await axios.get(config_url, { headers });
            if (undefined !== responseData && null !== responseData) {
                setAggregateStats(responseData.data.aggregated_stats);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    //   useEffect(() => {

    //     setInterval(() => {
    //         fetchNotificationDetails(); 
    //       }, 30000);


    //   }, []);

    const fetchNotificationDetails = async () => {
        setLoading(true)
        const token = data?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
            Authorization: authToken,
        };
        try {
            // const tenant_id = (userData && userData[0].user.is_superadmin) ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null ;
            const tenant_id = (userData && userData[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null;
            const config_url: string = `${BASE_URL}/reporting-service/tenants/${tenant_id}/notifications/?limit=10&page=1`;
            const responseData = await axios.get(config_url, { headers });
            if (undefined !== responseData && null !== responseData) {
                setNotification(responseData.data);
                setLoading(false);
            } else {
                setLoading(false);
            }


        } catch (error) {
            console.error('Error fetching Notification data:', error);
            setLoading(false);
        }
    }


    return (

        <div className="bg-box-trasparant p-3">
            <div className="row g-2">
                <div className="col-3  m-o">
                    <div className="deskboad-bg-box">
                        <div className="deskboad-notificastions-box"><div className="header-text1"><h4>Notifications</h4><ul className="list-notifi" style={{height:360}}>
                        {
                 loading ? 
                 <div style={{marginTop:"100"}}>
                 <Circles color="#7C7AFD" height={50} width={50} wrapperStyle={{justifyContent:"center",marginTop:"100px"}}  />
                 </div>
                     :
                     notification && notification.notifications.map((data:any) => (
                     <div><li><div className="list-notifications">
                     <div className="thumimage"> {data.entity_type.substring(0, 2)}</div>
                     <div className="notification-text">
                        <p>{data.message}</p>
                        {/* <small>{data.entity_type}</small> */}
                        <small>{moment.unix(parseInt(data.created_on)).format('YYYY-MM-DD')}</small> 
                    </div>
                    <div className="date-notifications">
                    </div></div></li>
                     </div>
                     ))
                        }
                           
                            </ul>
                            </div></div>
                    </div>
                </div>
                <div className="col-9">
                    <div className="row g-2">
                         
                         <div className="col-4  m-o">
                             <div className="deskboad-bg-box">
                                 <ul className="boxlist"><li> <h1 className="box-title">{data?.profile?.name === 'QWERX Admin'?'Organizations':'Organization'} </h1> {data?.profile?.name === 'QWERX Admin' &&<NavLink to="/organizations"><button className="addbtn-smaall">Add Organizations </button></NavLink>}</li><li><div className="icons-number" style={{marginTop:`${data?.profile?.name === 'QWERX Admin'?'0px':'46px'}`}}><div className="number-bold">{data?.profile?.name === 'QWERX Admin' ?aggregateStats?.tenant?.total : (userData && userData[0] !== undefined) && (userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].name : null)}</div><div className="">
                                     <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 640 512"  style={{fill:'#2c3789'}}><path d="M218.3 8.5c12.3-11.3 31.2-11.3 43.4 0l208 192c6.7 6.2 10.3 14.8 10.3 23.5H336c-19.1 0-36.3 8.4-48 21.7V208c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h64V416H112c-26.5 0-48-21.5-48-48V256H32c-13.2 0-25-8.1-29.8-20.3s-1.6-26.2 8.1-35.2l208-192zM352 304V448H544V304H352zm-48-16c0-17.7 14.3-32 32-32H560c17.7 0 32 14.3 32 32V448h32c8.8 0 16 7.2 16 16c0 26.5-21.5 48-48 48H544 352 304c-26.5 0-48-21.5-48-48c0-8.8 7.2-16 16-16h32V288z" /></svg>
                                 </div></div></li></ul>
 
                             </div>
                         </div>

                         <div className="col-4 m-o ">
                            <div className="deskboad-bg-box">
                                <ul className="boxlist"><li> <h1 className="box-title">Networks </h1><NavLink to="/networks"><button className="addbtn-smaall">Add Networks </button></NavLink></li><li><div className="icons-number"><div className="number-bold">{aggregateStats?.network?.total}</div><div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 640 512"  style={{fill:'#2c3789'}}><path d="M256 64H384v64H256V64zM240 0c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h48v32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96v32H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H240c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H192V288H448v32H400c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H560c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H512V288h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V192h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H240zM96 448V384H224v64H96zm320-64H544v64H416V384z" /></svg>
                                </div></div></li></ul>

                            </div>
                        </div>
                        <div className="col-4  m-o">
                            <div className="deskboad-bg-box">
                                <ul className="boxlist"><li> <h1 className="box-title">Devices </h1><NavLink to="/devices"><button className="addbtn-smaall">Add Devices </button></NavLink></li><li><div className="icons-number"><div className="number-bold">{aggregateStats?.device?.total}</div><div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 640 512" style={{fill:'#2c3789'}}><path d="M128 32C92.7 32 64 60.7 64 96V352h64V96H512V352h64V96c0-35.3-28.7-64-64-64H128zM19.2 384C8.6 384 0 392.6 0 403.2C0 445.6 34.4 480 76.8 480H563.2c42.4 0 76.8-34.4 76.8-76.8c0-10.6-8.6-19.2-19.2-19.2H19.2z" /></svg>
                                </div></div></li></ul>

                            </div>

                        </div>

                    </div>
                    <div className="row g-2">
                        <div className="col-12">
                            <div className="deskboad-bg-box mt-2">
                                <table className="table deskboad-table">
                                    <tbody>
                                    
                                    <tr>
                                        <td>
                                            <h3 className="table-text">Online devices </h3>
                                        </td>
                                        <td>
                                            <p className="data-table">{aggregateStats?.device?.online}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 className="table-text">Offline devices </h3>
                                        </td>
                                        <td>
                                            <p className="data-table">{aggregateStats?.device?.offline}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 className="table-text">Cloned devices </h3>
                                        </td>
                                        <td>
                                            <p className="data-table">{aggregateStats?.device?.cloned}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 className="table-text">Disabled devices </h3>
                                        </td>
                                        <td>
                                            <p className="data-table">{aggregateStats?.device?.disabled}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 className="table-text">Rogue devices </h3>
                                        </td>
                                        <td>
                                            <p className="data-table">{aggregateStats?.device?.rogue}</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            {/* <div className="row g-2" >
                <div className="col-12">
                    <div className="deskboad-bg-box mt-2" style={{height: 100}}>
                        <div id='preloader'>
                            <div className='spinner'>
                                <div className='loader'>
                                    <div className='moving-dot'></div>
                                    <div className="line-data">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}

        </div>



    );
};

export default DashBoardTable;