import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters, Column } from 'react-table';
import './networkDisplayTable.css';
import axios from "axios";
import { ApiService } from '../utility/api';
import upImage from '../../images/up.png';  // Import the image
import networksicon from '../../images/down.png';
//import networklist from '../../json/networklist.json';
import { Network } from '../../interface/network';
import EditNetwork from './editNetwork';
import moment from 'moment';
import CreateNetwork from './createNetwork';
import { UserIntrospect } from '../../interface/userIntrospect';
import Pagination from '../utility/pagination';
import { Circles } from 'react-loader-spinner';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {useSelector} from 'react-redux';

interface NetworkTablerops {
    data: any;
  }
  
  const columns: Column<Network>[] = [
    {
      Header: 'Network Name',
      accessor: 'name',
      disableSortBy:false,
    },
    {
      Header: 'Organization',
      accessor: (row: Network) => row.tenant?.name || '',
      disableSortBy:true,
    },
    {
      Header: 'Device Count',
      accessor: 'device_count',
      disableSortBy:true,
    },
    {
      Header: 'Modified On',
      accessor: 'modified_on',
      disableSortBy:false,
    },
    {
      Header: 'Modified By',
      accessor: (row: Network) => row.modified_by.display_name,
      disableSortBy:false,
    }
  ];
  


const NetworkDisplayTable: React.FC<NetworkTablerops> = ({ data }) => {

    const userData = useSelector((state:any) => state.userReducer.userData);
    const [tableData, setTableData] = useState<any>([]);
    const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordPerPage, setrecordPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isFilter, setIsFilter] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    // onst [isFilter, setIsFilter] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const [showAddModal,setShowAddModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [sortField, setSortField] = useState('created_on');
    const [sortOrder, setSortOrder] = useState('DESC');
  
    
      const displayModal = () => {
        setShowModal(true);
      }
      const closeModal = () => {
        setShowModal(false);
      }
  
      const displayAddModal = () => {
        setShowAddModal(true);
      }
      const closeAddModal = () => {
        setShowAddModal(false);
      }
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // setFilter
    } = useTable<any>(
      {
        columns,
        data: tableData,
        autoResetExpanded:false
      },
      // useFilters,
      useSortBy,
      // usePagination
    );
  
  
  
    const [selectedNetwork, setSelectedNetwork] = useState<Network>({
      id: '',
      name: '',
      tenant: {
        id: '',
        device_count: '',
        network_count: '',
        name: '',
        description: '',
        country: '',
        state: '',
        address_line_1: '',
        address_line_2: '',
        addon_data: '',
        config: '',
        is_enabled: false,
        created_on: '',
        modified_on: '',
        user: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        created_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        modified_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        city: '',
        zipcode: '',
        industry: {
          id: '',
          name: '',
          config: '',
          is_enabled: false,
          created_on: '',
          modified_on: '',
          created_by: '',
          modified_by: ''
        },
        stats: {
          device: {
            total: '',
          },
          network: {
            total: '',
          }
        }
      },
      created_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      modified_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      device_count: 0,
      serial_no: 0,
      is_enabled: false,
      is_deleted: false,
      created_on: '',
      modified_on: '',
      stats: {
        device: {
          total: '',
        },
        network: {
          total: '',
        }
      }
  
    });
    const [deletResponse, setDeleteResponse] = useState('');
  
    const [networkDetails, setNetworkDetails] = useState<Network>({
      id: '',
      name: '',
      tenant: {
        id: '',
        device_count: '',
        network_count: '',
        name: '',
        description: '',
        country: '',
        state: '',
        address_line_1: '',
        address_line_2: '',
        addon_data: '',
        config: '',
        is_enabled: false,
        created_on: '',
        modified_on: '',
        user: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        created_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        modified_by: {
          id: '',
          email: '',
          first_name: '',
          last_name: '',
          display_name: ''
        },
        city: '',
        zipcode: '',
        industry: {
          id: '',
          name: '',
          config: '',
          is_enabled: false,
          created_on: '',
          modified_on: '',
          created_by: '',
          modified_by: ''
        },
        stats: {
          device: {
            total: '',
          },
          network: {
            total: '',
          }
        }
      },
      created_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      modified_by: {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      device_count: 0,
      serial_no: 0,
      is_enabled: false,
      is_deleted: false,
      created_on: '',
      modified_on: '',
      stats: {
        device: {
          total: '',
        },
        network: {
          total: '',
        }
      }
  
    });
  
  
    const fetchNetworkDetails = async (networkDetails: Network) => {
  
      try {
        const token = data?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        const networkDetailsURL: string = `${BASE_URL}/asset-service/tenants/${networkDetails.tenant.id}/networks/${networkDetails.id}/`;
        const response = await axios.get(networkDetailsURL, { headers });
  
        setNetworkDetails(response.data.network);
  
  
      } catch (error: any) {
        return { status: false, message: error.response.data.error.message, data: {} }
      }
  
    }
  
    /*
    Introspect call
    */
    /* useEffect(() => {
      fetchInrospectData();
      }, []);*/
  
    /*
    Create,Edit,Delete
    */
    /*const fetchInrospectData = async () => {
      try {
      const token = data?.access_token;
      const authToken = `Bearer ${token}`;   
      const headers = {
        Authorization: authToken,
      };
      
      
      setUserIntrospectData(responseData.data.user);
    }catch (error) {
      console.error('Error fetching data:', error);
    }
  }*/
    /*create a new modal while click on create*/
  
  
    const fetchData = async (searchString: string = '',sortingField: string = '', sortingType: string = '') => {
      setLoading(true)
      try {
        let isSuperAdmin;
        const token = data?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        // const config_url = BASE_URL + `/auth-service/users/introspect/`;
        // const responseData = await axios.get(config_url, { headers });
        // const tenant_id = (userData && userData[0].user.is_superadmin) ? '*' : userData[0].user.tenants.length > 0 ? userData[0].user.tenants[0].id : null ;
        const tenant_id = (userData && userData[0]?.user?.roles[0]?.role?.name === 'SYSTEM_ADMIN') ? '*' : userData[0]?.user?.tenants?.length > 0 ? userData[0]?.user?.tenants[0]?.id : null ;
          
        // if (undefined !== responseData && null !== responseData.data)
        //   isSuperAdmin = responseData.data.user.is_superadmin;
        // if (isSuperAdmin) {
          try {
            const networkURL: string = `${BASE_URL}/asset-service/tenants/${tenant_id}/networks/?page=${currentPage}&limit=${recordPerPage}&k=${searchString}&sort_field=${sortingField}&sort_order=${sortingType}`;
            const response = await axios.get(networkURL, {
              headers
            });
            const networkList = response.data.networks;
            // const networkListData = networklist;
            //const networkList = networkListData.networks;
            setTotalRecords(response.data.total_records);
  
            const formattedNetworks = networkList.map((network: any) => ({
              id: network.id,
              created_by: network.created_by,
              modified_by: network.modified_by,
              device_count: (undefined !== network.stats && undefined !== network.stats.device) ? network.stats.device.total : '0',
              serial_no: network.serial_no,
              name: network.name,
              tenant: network.tenant,
              is_enabled: network.is_enabled,
              is_deleted: network.is_deleted,
              created_on: network.created_on,
              modified_on: moment.unix(parseInt(network.modified_on)).format('YYYY-MM-DD')
            }));
  
            setTableData(formattedNetworks);
            setLoading(false)
  
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
          }
        // } else {
        //   setLoading(false)
        // }
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false)
      }
    }
  
    // useEffect(() => {
      
    //   fetchData(searchStr,sortField,sortOrder);
    // }, [deletResponse, currentPage, recordPerPage, userData]);
  
    useEffect(() => {
      
      fetchData(searchStr,sortField,sortOrder);
    }, [currentPage, recordPerPage]);
  
    const handleEdit = (message:any) => {
      fetchData(searchStr,sortField,sortOrder);
      setSuccessMessage(message.message);
      setTimeout(()=>{
        setSuccessMessage(null);
      },10000)
    };
  
    const handleDelete = (message: any) => {
      fetchData(searchStr,sortField,sortOrder);
      setSuccessMessage(message.message);
      setTimeout(()=>{
        setSuccessMessage(null);
      },10000)
    }
  
    const handleSave = (message:any) => {
      // fetchData(searchStr);
      closeModal(); 
      setShowModal(false);
      fetchData(searchStr,sortField,sortOrder);
      setSuccessMessage(message.message);
      setTimeout(()=>{
        setSuccessMessage(null);
      },10000)
    };
  
    const handleSorting = (data:any, sort:string) => {
  
      let sortFieldString = ''
      if(data.id === 'name'){
        sortFieldString = 'name';
      }else if(data.id === 'created_on'){
        sortFieldString= 'created_on';
      }else if(data.id === 'modified_on'){
        sortFieldString= 'modified_on';
      }else{
        sortFieldString= data.id;
      }
      if(sortFieldString !== '' && !data.disableSortBy){
        setSortField(sortFieldString);
        setSortOrder(sort);
        fetchData(searchStr,sortFieldString,sort);
      }
    
  
    }
  

    return(
        <div className="bg-box p-3">
      <div className="App ">
        <h3 className='titelcolostyle'>
        <FontAwesomeIcon icon={faNetworkWired as IconProp} />
          <span className='networkHeader'>Networks</span>
          </h3>
        <div className='header-fuctions'>

          <div><input
            type="text"
            placeholder="Search Name"
            // onChange={(e) => setFilter('name', e.target.value)}
            onChange={(e) => {(e.target.value.length >= 3 || e.target.value.length === 0) && fetchData(e.target.value,sortField,sortOrder); setIsFilter((e.target.value.length > 0) ? true : false); setSearchStr(e.target.value)}}
            className="form-control mb-3 cutomesearchbox"
          />

          </div>
          <div>
            {/* <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModalNetwork"
              className='addbtn'>
              Add Network
            </button> */}
            <button
               onClick={() => { displayAddModal() }}
              className='addbtn'>
              Add Network
            </button>
          </div>

        </div>
        {
              null !== successMessage &&
              <div>
                <p style={{ color: 'blue' }}>Success: {successMessage}</p>
              </div>
            }
        {/* Commented Response message temporarily  */}
        {/* <div>
          {
            <div>
              <p style={{ color: 'blue' }}>{deletResponse}</p>
            </div>
          }
        </div> */}

        <div className="scrollable-table-test">
          {loading ?
            <div style={{ marginTop: "100" }}>
              <Circles color="#7C7AFD" height={50} width={50} wrapperStyle={{ justifyContent: "center", marginTop: "150px" }} />
            </div>
            :
            <table {...getTableProps()} className="table ">
              <thead className='sticky-top'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      //   {column.render('Header')}
                      //   <span>
                      //     {/* {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''} */}
                      //     {column.isSortedDesc ? (
                      //       <img src={upImage} onClick={() => handleSorting(column,'DESC')} className='icon-left-m' alt="Down Arrow" />

                      //     ) : (
                      //       <img src={networksicon} onClick={() => handleSorting(column,'ASEC')} className='icon-left-m' alt="Up Arrow" />
                      //     ) }
                      //   </span>
                      // </th>

                      <th onClick={()=>handleSorting(column, sortOrder === 'DESC'?'ASC':'DESC')}>
                    {column.render('Header')}
                    {
                      !column.disableSortBy && 
                      <span>
                      {
                        
                        sortOrder === 'DESC' ? (
                          <img src={upImage}  className='icon-left-m' alt="Down Arrow" onClick={()=>handleSorting(column,'ASC')} />

                        ) : (
                          <img src={networksicon} className='icon-left-m' alt="Up Arrow" onClick={()=>handleSorting(column,'DESC')} />
                        )
                      }
                      
                    </span>
                    }
                    
                  </th>

                    ))}
                    {/*<th>Action</th>*/}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                      <td>
                        {/* <button onClick={() => createData()} className="btn btn-success mr-2">
                      Create

                    </button> */}
                        <div className='list-action'>
                          {/*<div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
                      
                  </div>*/}

                    {/* <button
                      // onClick={() => openEditModal(row.original)}
                      className="btn btn-secondary btn-sm mr-2" data-bs-toggle="modal" 
                      data-bs-target="#exampleModal"
                      onClick={() => {setSelectedNetwork(row.original); fetchNetworkDetails(row.original);}}
                      
                    >
                      View
                    </button> */}
                     <button
                      className="btn btn-secondary btn-sm mr-2" 
                      onClick={() => {
                        setSelectedNetwork(row.original);
                        fetchNetworkDetails(row.original);
                        displayModal();
                        }}
                      
                    >
                      View
                    </button>

                    </div>
                  
                    {/* <button
                      onClick={() => handleDelete(row.original.id)}
                      className="btn btn-danger"
                    >
                      Delete
                    </button> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
        </div>
        <div className="paginationOuterDiv">
          <div style={{ width: "35%" }}>
          <span>Select # of Rows  &nbsp;</span>
            <select
              value={recordPerPage}
              onChange={(e) => {
                setrecordPerPage(Number(e.target.value)); setCurrentPage(1);
              }}
              style={{ width: 50, marginTop: 10, textAlign:"center" }}
            >
              {[25, 50, 75, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "65%" }}>
            <Pagination key={currentPage}
              totalRows={totalRecords}
              pageChangeHandler={setCurrentPage}
              rowsPerPage={recordPerPage}
              setFilter={setIsFilter}
              currentPageNumber={currentPage}
            />
          </div>
        </div>


        <CreateNetwork onSave={handleSave} userData={data} isAddOpen={showAddModal} closeAddModal={closeAddModal} />

        <EditNetwork data={selectedNetwork} onEdit={handleEdit} onDelete={handleDelete} userData={data} network={networkDetails} isOpen={showModal} closeModal={closeModal} />

      </div>
    </div>
    );

}

export default NetworkDisplayTable;