
import orgicon from '../../images/orgicon.svg';
import { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import './createNetwork.css';
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired  } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';

interface DropdownOption {
  value: string;
  label: string;
}

interface createNetworkProps {
  onSave:(message:any) => void;
  userData: any;
  isAddOpen: boolean;
  closeAddModal: any;
}


const CreateNetwork: React.FC<createNetworkProps> = ({ onSave, userData, isAddOpen, closeAddModal }) => {


  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      organizationName: '', networkName: ''
    }
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [modalOpen, setModalOpen] = useState(isAddOpen);
  const userDetails = useSelector((state: any) => state.userReducer.userData);
  useEffect(() => {
    setModalOpen(isAddOpen);
  }, [isAddOpen]);


  const handleSelectElement = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
  };

  //Fetch Organization Details
  const fetchOrganizationData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenant_id = (userDetails && userDetails[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userDetails[0].user.tenants.length > 0 ? userDetails[0].user.tenants[0].id : null;
      const tenantURL:string =`${BASE_URL}/auth-service/tenants/`;
      const response = await axios.get(tenantURL, { headers });
      let organizationsList = response.data.tenants;
      if(tenant_id !== '*'){
        organizationsList = organizationsList.filter((item:any) => (item.id === tenant_id))
      }
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = organizationsList.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setOptions(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching industry data:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationData();
  }, [])


  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  /*
  Enroll New Network
  */
  const onSubmit = async (data: {
    organizationName: string, networkName: string
  }) => {
    data.organizationName = selectedValue;
    data.networkName = capitalizeFirstLetter(data.networkName);
    // return false;
    if (data.organizationName !== '' && data.networkName !== '') {
      const responseData: any = await createNetwork(data);
      closeAddModal();
      //create new User Map with new Organization
      if (!responseData.status) {
        setErrorMessage(responseData.message);
        setSuccessMessage(null);
        setSelectedValue('')
        return false;
      } else {
        // setSuccessMessage("New network created successfully");
        setErrorMessage(null);
        setSelectedValue('')
        reset();
        if (undefined !== responseData.data)
        reset();
        onSave({status:true, message:'New network created successfully'});
        return false;
      }
    } else {
      reset();
      setErrorMessage(null);
      setSuccessMessage(null);
      setSelectedValue('')
    }

  };

  const createNetwork = async (formData: any) => {

try{
  let network = {
    name: formData.networkName
  }
  const token = userData?.access_token;
  const authToken = `Bearer ${token}`;
  const headers = {
    Authorization: authToken,
  };
  const tenantId = selectedValue;
  const baseURL:string = `${BASE_URL}/asset-service/tenants/${tenantId}/networks/`;

  const response = await axios.post(baseURL, network, { headers });
  
  return {status: true, message:'New network created successfully', data:response}
}catch(error:any){
return {status: false, message:error.response.data.error.message, data:{}}
}
    


  }

  return (
    <div>
       <Modal  backdrop="static" show={modalOpen} onHide={() => { closeAddModal(); setModalOpen(false) }} >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><h3 className="titelcolostyle createNetwork">
            <FontAwesomeIcon icon={faNetworkWired as IconProp} />
              <span className="createNetworkTxt">Enroll New Network</span>
              </h3></h5>
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={() => { reset();}}></button> */}
            <button type="button" className="btn-close"  onClick={() => { reset();closeAddModal();}}></button>
          </div>
          <form className="create-network-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              {null !== errorMessage &&
                <div>
                  <p style={{ color: 'red' }}>Error: {errorMessage}</p>
                </div>
              }
              {
                null !== successMessage &&
                <div>
                  <p style={{ color: 'blue' }}>Success: {successMessage}</p>
                </div>
              }
              {/* <form className="create-network-form" onSubmit={handleSubmit(onSubmit)}> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="label">Organization Name <span className="require">*</span></label>
                    <select
                      className={`form-select ${errors.organizationName ? 'is-invalid' : ''}`}
                      id="organizationName"
                      value={selectedValue}
                      {...register('organizationName', {
                        required: true
                      })}
                      onChange={(e)=>setSelectedValue(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors.organizationName && errors.organizationName.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Organization Name is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="label">Network Name<span className="require">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.networkName ? 'is-invalid' : ''}`}
                      placeholder="Enter Network Name"
                      {...register('networkName', {
                        required: true,
                      })}
                    />
                    {errors.networkName && errors.networkName.type === "required" &&
                      <p className="invalid-feedback d-block errorFont">Network Name is required.</p>}
                  </div>
                </div>
              </div>



              {/* </form> */}
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-light btn-sm" onClick={() => { reset(); }} data-bs-dismiss="modal">Close</button> */}
              <button type="button" className="btn btn-light btn-sm" onClick={() => { reset();closeAddModal(); }}>Close</button>
              <button type="submit" className="btn btn-secondary btn-sm">Save</button>
            </div>
          </form>
        </div>
      </div>
      </Modal>
    </div>
  )
}

export default CreateNetwork;