import { useState,useEffect } from "react";
import { device } from "../../interface/device";
import axios from "axios";
import { Modal } from "react-bootstrap";

interface DeleteDeviceModalProps {
    data:device | null;
    // onDelete: (id:any) => void;
    onDelete:(message:any) => void;
    userData:any;
    isModalOpen:boolean;
    closeModal:any;
    closeDeleteModal:any;
}

const DeleteDevice: React.FC<DeleteDeviceModalProps> = ({data,onDelete,userData,isModalOpen,closeModal,closeDeleteModal}) => {

  const [deleteModalOpen, setDeleteModalOpen] = useState(isModalOpen);

  
  useEffect(() => {
    setDeleteModalOpen(isModalOpen);
  }, [isModalOpen]);

  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
    const handleDelete = async (device: device | null) => {
        try {
          
          const token = userData?.access_token;
          const authToken = `Bearer ${token}`;
          const headers = {
            Authorization: authToken,
          };
          const tenantId = device?.tenant.id;
          const deviceId = device?.id;
          const baseURL = `${BASE_URL}/asset-service/tenants/${tenantId}/devices/${deviceId}`;
    
          const response = await axios.delete(baseURL,{headers});
          onDelete(deviceId);
          onDelete({status:true, message:"Device deleted successfully"});
        
        } catch (error) {
          console.error('Error deleting device:', error);
        }
      };

      return (

        // <div className="modal fade " id="exampleDeleteDevice"
        // tabIndex={-1} aria-labelledby="exampleModalLabel"
        // aria-hidden="true">
        <div>
            <Modal dialogClassName='delete-dialog'  backdrop="static" show={deleteModalOpen} onHide={() => { closeDeleteModal(); setDeleteModalOpen(false) }} style={{marginTop:'5%'}}>
        <div>
         
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <h3 className="titelcolostyle"> Confirm Deletion</h3></h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <button type="button" className="btn-close"  aria-label="Close" onClick={() => {closeDeleteModal(); setDeleteModalOpen(false) }}></button>
            </div>
            <div className="modal-body">
              <h2 style={{fontSize:'15px'}}>Are you sure you want to delete this device?</h2>
              
            </div>
            <div className="modal-footer">
                {/* <button type="button" className="btn btn-danger"
                data-bs-toggle="modal" 
                data-bs-target="#exampleModalDeviceEdit"
                 onClick={() => handleDelete(data)} data-bs-dismiss="modal">Yes</button> */}
                 <button type="button" className="btn btn-danger"
                 onClick={() => {handleDelete(data);setDeleteModalOpen(false);closeModal();}} >Yes</button>
                {/* <button type="button" className="btn btn-primary" data-bs-dismiss="modal">No</button> */}
                <button type="button" className="btn btn-light btn-sm" onClick={() => {closeDeleteModal(); setDeleteModalOpen(false) }}>No</button>
            </div>
           
         
        </div>
        </Modal>
      </div>
    );

};
export default DeleteDevice;