import { Organization } from '../../interface/organization';
import orgicon from '../../images/orgicon.svg';
import { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import DeleteOrganization from './deleteOrganization';
import { useForm } from "react-hook-form";
import { UserDetails } from '../../interface/userDetails';
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import './editOrganization.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseLaptop } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface editOrganizationProps {
  // data: Organization;
  userData: any;
  onEdit: (message: any) => void;
  onDelete: (message: any) => void;
  // userDetails:UserDetails;
  tenant: any;
  isOpen: boolean;
  closeModal: any;
}

interface FormState {
  name: string;
  country: string;
  industry: string;
  state: string;
}
interface DropdownOption {
  value: string;
  label: string;
}

const EditOrganization: React.FC<editOrganizationProps> = ({ userData, tenant, isOpen, closeModal, onEdit, onDelete }) => {

  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;

  const [name, setName] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [tenantData, setTenentData] = useState(tenant);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);


  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: '',
      industry: '', country: '', state: '', email: '', city: '', firstName: '', lastName: '', mobileNumber: ''
      , address_line_1: '', address_line_2: '', zipCode: ''
    }
  });

  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showButtons, setShowButtons] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [countryList, setCountryList] = useState<DropdownOption[]>([]);
  const [stateList, setStateList] = useState<DropdownOption[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [userExist, setUserExist] = useState(true)

  const displayDeleteModal = () => {
    setShowDeleteModal(true);
  }
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }


  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {

  };

  const handleSelectIndustry = (industryCode: any) => {
    setValue('industry', industryCode.value)

  };

  //onload data
  useEffect(() => {
    async function getInitialData() {
      await countryData();
      await fetchIndustryData();
      if (tenantData !== null) {
        setValue('name', tenantData.name);
        setValue('industry', tenantData.industry.id);
        setValue('email', (tenantData.user !== null && tenantData.user !== undefined) ? tenantData.user.email : '');
        setValue('city', tenantData.city);
        setValue('firstName', (tenantData.user !== null && tenantData.user !== undefined) ? tenantData.user.first_name : '');
        setValue('lastName', (tenantData.user !== null && tenantData.user !== undefined) ? tenantData.user.last_name : '');
        setValue('mobileNumber', (tenantData !== null && tenantData.phone));
        setValue('address_line_1', tenantData.address_line_1);
        setValue('address_line_2', tenantData.address_line_2);
        setValue('zipCode', tenantData.zipcode);
        fetchStateList(tenantData.country).then(() => {
          setTimeout(() => {
            setValue('state', tenantData.state);
            setSelectedState(tenantData.state)
          }, 100)
        });

      }
    }
    getInitialData();
  }, [tenantData]);


  const resetEdit = () => {
    if (tenantData !== null) {
      setValue('name', tenantData.name);
      setValue('industry', tenantData.industry.id);
      setValue('email', (tenantData.user !== null && tenantData.user !== undefined) ? tenantData.user.email : '');
      setValue('city', tenantData.city);
      setValue('firstName', (tenantData.user !== null && tenantData.user !== undefined) ? tenantData.user.first_name : '');
      setValue('lastName', (tenantData.user !== null && tenantData.user !== undefined) ? tenantData.user.last_name : '');
      setValue('mobileNumber', (tenantData !== null && tenantData.phone));
      setValue('address_line_1', tenantData.address_line_1);
      setValue('address_line_2', tenantData.address_line_2);
      setValue('zipCode', tenantData.zipcode);
      setValue('country', tenantData.country);
      setSelectedCountry(tenantData.country)
      setValue('state', tenantData.state);
      setSelectedState(tenantData.state)


    }
  }
  const handleSelectCountry = (countryCode: any) => {
    fetchStateList(countryCode.value);
    setValue('country', countryCode.value)
    setSelectedCountry(countryCode.value)
  };
  const handleSelectState = (selectedState: any) => {
    setValue('state', selectedState.value)
    setSelectedState(selectedState.value)
  };

  const countryData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantURL = `${BASE_URL}/asset-service/resources/countries/`;
      const response = await axios.get(tenantURL, { headers });
      const countryList = response.data.countries;
      const dropdownOptions: DropdownOption[] = countryList.map((item: any) => ({
        value: item.iso_code_2,
        label: item.name,
      }));

      setCountryList(dropdownOptions);
      setValue('country', tenantData.country);
      setSelectedCountry(tenantData.country)
    }
    catch (error) {
      console.error('Error fetching country data:', error);
    }
  }

  const fetchStateList = async (countryCode: string) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantURL = `${BASE_URL}/asset-service/resources/states/?country_code=${countryCode}`;
      const response = await axios.get(tenantURL, { headers });
      const stateListData = response.data.states;
      const dropdownOptions: DropdownOption[] = stateListData.map((item: any) => ({
        value: item.code,
        label: item.name,
      }));

      setStateList(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching state list data:', error);
    }
  }



  /*
  userDetails based on the tenant ID
  */
  const fetchIndustryData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const industryURL = `${BASE_URL}/auth-service/industries/`;
      const response = await axios.get(industryURL, { headers });
      const industryList = response.data.industries;
      const dropdownOptions: DropdownOption[] = industryList.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setOptions(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching industry data:', error);
    }
  };



  /*
  submit edit Organization with email
  */
  const onSubmit = async (data: {
    name: string, industry: string, state: string, country: string, email: string
    city: string, firstName: string, lastName: string, mobileNumber: string, address_line_1: string,
    address_line_2: string
  }) => {

    data.industry = data.industry;
    if (data.name !== '' && data.industry !== '' && data.state !== '' && data.country !== '' &&
      data.email !== '' && data.city !== '' && data.firstName !== '' && data.lastName !== ''
      && data.mobileNumber !== '' && data.address_line_1 !== '' && data.address_line_2 !== '') {
      if (!userExist) {

        const responseData: any = await editNewOrganization(data);
        if (!responseData.status) {
          setErrorMessage(responseData.message);
          // onEdit({status:false, message:responseData.message});
          setSuccessMessage(null);
          return false;
        } else {
          if (data.email !== tenantData?.user?.email) {
            const userDataOrganization: any = await createUserForNewOrganization(tenantData.id, data);
            if (undefined !== userDataOrganization.response && userDataOrganization.response.data.error) {
              setErrorMessage(userDataOrganization.response.data.error.message);
              setSuccessMessage(null);
              return false;
            } else {
              setSuccessMessage(responseData.message);
              setErrorMessage(null);
              if (undefined !== responseData.data)
                closeModal(); setModalOpen(false); reset(); setTenentData({})
              onEdit({ status: true, message: responseData.message });

              return false;
            }

          } else {
            setSuccessMessage(responseData.message);
            setErrorMessage(null);
            if (undefined !== responseData.data)
              closeModal(); setModalOpen(false); reset(); setTenentData({})
            onEdit({ status: true, message: responseData.message });

            return false;
          }

        }

      } else {
        setErrorMessage('Email id already in use, please choose some other email id');
        setSuccessMessage(null);
        return false;
      }
    } else {
      //reset();
      setErrorMessage(null);
    }


  };
  const editNewOrganization = async (formData: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      let tenantId = tenantData != null ? tenantData.id : "";
      const edit_organization_URL: string = `${BASE_URL}/auth-service/tenants/${tenantId}/`;

      let tenant = {
        name: formData.name,
        industry: formData.industry,
        country: formData.country,
        state: formData.state,
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        zipcode: formData.zipCode,
        city: formData.city,
        email: formData.email,
        phone: formData.mobileNumber

      }
      const response = await axios.put(edit_organization_URL, tenant, { headers });
      return { status: true, message: 'Organization updated successfully', data: response }
    } catch (error: any) {
      return { status: false, message: error.response.data.error.message, data: {} }
    }

  }

  const editForm = () => {
    setIsDisabled(false);
    setEditButton(false);
    setShowButtons(true);
  }
  const createUserForNewOrganization = async (tenant_id: any, formData: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const New_Organization_MAP_TO_USER_URL: string = `${BASE_URL}/auth-service/tenants/${tenant_id}/users/`;

      let userOrganization = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        roles: [process.env.REACT_APP_ORG_ADMIN], //temporary role hardcoded
        phone: formData.mobileNumber
      }
      const response = await axios.post(New_Organization_MAP_TO_USER_URL, userOrganization, { headers });
      return response;
    } catch (error) {
      return error;
    }

  }
  const handleEmailChange = async (emailId: string) => {
    if (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(emailId)) {
      try {
        const token = userData?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        // const tenantId = data?.tenant.id;
        const supervisorURL = `${BASE_URL}/auth-service/tenants/*/users/?email=${emailId}`;
        try {
          const response = await axios.get(supervisorURL, {
            headers
          });
          const userList = response.data.users;
          if (userList.length > 0 && tenantData.user.email !== emailId) {
            setUserExist(true)
            setValue('firstName', userList[0].meta_data.first_name)
            setValue('lastName', userList[0].meta_data.last_name)
            setErrorMessage('Email id already in use, please choose some other email id');
            setSuccessMessage(null);
          } else {
            setUserExist(false)
            setErrorMessage(null);
            setSuccessMessage(null);
          }

        } catch (error) {
          setUserExist(false);
          setErrorMessage(null);
          setSuccessMessage(null);
        }
      } catch (error) {
        setUserExist(false);
        setErrorMessage(null);
        setSuccessMessage(null);
      }

    } else {
      delete errors["email"];
      setUserExist(false);
      setErrorMessage(null);
      setSuccessMessage(null);
    }
  }
  return (
    <div>
      <Modal dialogClassName='edit-organization-dialog' backdrop="static" show={modalOpen} onHide={() => { closeModal(); setModalOpen(false); reset(); setTenentData({}) }} >
        {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
        <div>

          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <h3 className="titelcolostyle editOrganizationHeader">
                <FontAwesomeIcon icon={faHouseLaptop as IconProp} />
                <span className="editOrganizationHeaderText">Organizations</span>
              </h3></h5>
            <button type="button" className="btn-close"
              onClick={() => {
                setErrorMessage(null); setSuccessMessage(null); setIsDisabled(true);
                setEditButton(true); setShowButtons(false); closeModal(); setModalOpen(false);
              }}
            ></button>
          </div>
          <div className="modal-body">
            {null !== errorMessage &&
              <div>
                <p style={{ color: 'red' }}>Error: {errorMessage}</p>
              </div>
            }
            {
              null !== successMessage &&
              <div>
                <p style={{ color: 'blue' }}>Success: {successMessage}</p>
              </div>
            }
            <form className="edit-organization-form" onSubmit={handleSubmit(onSubmit)}>
              {/* OrganizationName and Email ID */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Organization Name <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Organization name"
                      maxLength={30}
                      {...register('name', {
                        required: true,
                        minLength: 2,
                        maxLength: 30,
                        pattern: /^[\w\-.\s]+$/
                      })}
                      disabled={isDisabled}
                    />
                    {errors.name && errors.name.type === "required" &&
                      <p className="invalid-feedback d-block">Name is required.</p>}
                    {errors.name && errors.name.type === "minLength" &&
                      <p className="invalid-feedback d-block">Name should contain at least 2
                        characters.</p>}
                    {errors.name && errors.name.type === "maxLength" &&
                      <p className="invalid-feedback d-block">Maximum 30 characters allowed.</p>}
                    {errors.name && errors.name.type === "pattern" &&
                      <p className="invalid-feedback d-block">Name should be alphanumeric.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email ID <span className="require">*</span></label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Id"
                      {...register('email', {
                        required: true,
                        pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                      })}
                      disabled={isDisabled}
                      onChange={(e) => { handleEmailChange(e.target.value) }}
                    />
                    {errors.email && errors.email.type === "required" &&
                      <p key={errors.email && errors.email.type} className="invalid-feedback d-block errorFont">Email ID is required.</p>}
                    {errors.email && errors.email.type === "pattern" &&
                      <p key={errors.email && errors.email.type} className="invalid-feedback d-block errorFont">Enter correct Email ID.</p>}
                    {errors.email && errors.email.type === "alreadyInUse" &&
                      <p key={errors.email ? errors.email.type : "email_no_error"} className="invalid-feedback d-block errorFont">Email id already in use</p>}
                  </div>
                </div>
              </div>

              {/* First Name and Last Name*/}
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name: <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      {...register('firstName', {
                        required: true
                      })}
                      disabled={isDisabled}
                    />
                    {errors.firstName && errors.firstName.type === "required" &&
                      <p className="invalid-feedback d-block">First Name is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name: <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter LastName"
                      {...register('lastName', {
                        required: true
                      })}
                      disabled={isDisabled}
                    />
                    {errors.lastName && errors.lastName.type === "required" &&
                      <p className="invalid-feedback d-block">Last Name is required.</p>}
                  </div>
                </div>
              </div>

              {/* Address 1 and Address 2*/}
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address 1: <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address 1"
                      {...register('address_line_1', {
                        required: true
                      })}
                      disabled={isDisabled}
                    />
                    {errors.address_line_1 && errors.address_line_1.type === "required" &&
                      <p className="invalid-feedback d-block">Address Line 1 is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address 2: <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address 2"
                      {...register('address_line_2', {
                        required: true
                      })}

                      disabled={isDisabled}
                    />
                    {errors.address_line_2 && errors.address_line_2.type === "required" &&
                      <p className="invalid-feedback d-block">Address 2. is required.</p>}
                  </div>
                </div>
              </div>
              {/* Country  and State */}
              <div className='row'>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country <span className="require">*</span></label>
                    <Select
                      isDisabled={isDisabled}
                      className={`basic-single ${errors.country ? 'is-invalid' : ''} selectFieldFont`}
                      classNamePrefix="select"
                      id="country"
                      {...register('country', {
                        required: true,
                      })}
                      onChange={(e) => handleSelectCountry(e)}
                      options={countryList}
                      isSearchable={true}
                      value={tenantData &&
                        countryList.filter(country =>
                          country.value === selectedCountry)
                      }
                    />
                    {errors.country && errors.country.type === "required" &&
                      <p className="invalid-feedback d-block">Country is required.</p>}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>State <span className="require">*</span></label>
                    <Select
                      isDisabled={isDisabled}
                      className={`basic-single ${errors.state ? 'is-invalid' : ''} selectFieldFont`}
                      classNamePrefix="select"
                      id="state"
                      {...register('state', {
                        required: true,
                      })}
                      onChange={(e) => handleSelectState(e)}
                      options={stateList}
                      isSearchable={true}
                      value={tenantData &&
                        stateList.filter(state =>
                          state.value === selectedState)
                      }
                    />
                    {errors.state && errors.state.type === "required" &&
                      <p className="invalid-feedback d-block">State is required.</p>}
                  </div>
                </div>

              </div>

              {/* City  and Zip Code */}
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter City"
                      {...register('city', {
                        required: true
                      })}
                      disabled={isDisabled}
                    />
                    {errors.city && errors.city.type === "required" &&
                      <p className="invalid-feedback d-block">City is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ZipCode: <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Zip Code"
                      {...register('zipCode', {
                        required: true
                      })}
                      disabled={isDisabled}
                    />
                    {errors.zipCode && errors.zipCode.type === "required" &&
                      <p className="invalid-feedback d-block">ZipCode  is required.</p>}
                  </div>
                </div>
              </div>

              {/* Mobile Number  and Industry */}
              <div className='row'>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Mobile No: <span className="require">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      {...register('mobileNumber', {
                        required: true
                      })}
                      disabled={isDisabled}
                    />
                    {errors.mobileNumber && errors.mobileNumber.type === "required" &&
                      <p className="invalid-feedback d-block">Phone No. is required.</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Industry Name <span className="require">*</span></label>
                    <Select
                      isDisabled={isDisabled}
                      className={`basic-single ${errors.industry ? 'is-invalid' : ''} selectFieldFont`}
                      classNamePrefix="select"
                      id="industry"
                      {...register('industry', {
                        required: true,
                      })}
                      onChange={(e) => handleSelectIndustry(e)}
                      options={options}
                      isSearchable={true}
                      value={tenantData &&
                        options.filter(industry =>
                          industry.value === tenantData.industry.id)
                      }
                    />
                    {errors.country && errors.country.type === "required" &&
                      <p className="invalid-feedback d-block">Country is required.</p>}
                    
                  </div>
                </div>


              </div>

              <div className="modal-footer">

                {editButton && <>
                  <button type="button"
                    className="btn btn-danger btn-sm" onClick={() => displayDeleteModal()}>Delete</button>
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => editForm()}
                  >Edit </button></>}
                {showButtons &&
                  <><button type="submit" className="btn btn-secondary btn-sm"
                  >Save </button>
                    <button type="button" className="btn btn-light btn-sm" onClick=
                      {() => {
                        setErrorMessage(null); setSuccessMessage(null); setIsDisabled(true);
                        setEditButton(true); setShowButtons(false); resetEdit();
                      }} data-bs-dismiss="modal"
                    >Cancel </button></>
                }
              </div>
            </form>

          </div>


        </div>
        {
          showDeleteModal &&
          <DeleteOrganization userData={userData} data={tenantData} onDelete={onDelete} isModalOpen={showDeleteModal} closeModal={closeModal} closeDeleteModal={closeDeleteModal} />
        }

      </Modal>
    </div>

  )
}

export default EditOrganization;


