import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import {useAuth} from 'oidc-react';
import DashboardComponent from './components/dashboard';
// images call
import qwerxImage from './images/QWERX---Enterprise-secure-perimeter.svg'; // Adjust the path based on your directory structure

import networksicon from './images/active-network.png';
import Devicesicon from './images/active-divies.png';
import Contacticon from './images/active-contact.png';
import TenantTable from './components/organization/organizationTable';

import DeviceTable from './components/device/deviceTable';

import NetworkDisplayTable from './components/network/networkDisplayTable';



import DashBoardTable from './components/dashboard/dashboardTable';
import ContactTable from './components/contact/contact';
import AuditLogTable from './components/audit/auditLogTable';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCells,faHouseLaptop,faNetworkWired,faMicrochip,faAddressBook,faArrowRightFromBracket,faSignOut,faTableList, faLaptop  } from '@fortawesome/free-solid-svg-icons';


import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AuthProvider } from 'oidc-react';
import axios from 'axios';
import { useDispatch} from 'react-redux';
import {setUserDetails} from './store/actions/userActions';
function App() {
  const [url, setUrl] = useState('');
  const auth = useAuth();
  const dispatch = useDispatch();
  const [userApiData, setUserApiData] = useState<any>([])
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const getUserData = async () => {
    const token = auth?.userData?.access_token;
    const authToken = `Bearer ${token}`;   
    const headers = {
      Authorization: authToken,
    };
    try{
      const config_url:string = `${BASE_URL}/auth-service/users/introspect/`;
      const responseData = await axios.get(config_url,{headers});
      if(responseData){
        setUserApiData(responseData.data);
        dispatch(setUserDetails(responseData.data));
      }else{
        dispatch(setUserDetails({}));
      }
    }catch(error){
      console.log(error,"error")
    }
    

   
  }
useEffect(() => {
  setUrl(window.location.pathname);
  async function getData (){
      await getUserData();
  }
  if(auth.userData !== null){
  getData();
}
 
},[url,auth.userData]);
  return (
    
      <>
      {
       auth.userData !== null &&  userApiData !== null && 
        <Router>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 border-primary">
              <img src={qwerxImage} className="m-4" alt="QWERX Logo" />
              <div>
                <ul className="list-group">
                  <li className="list-group-item">
                  
                    
                    <NavLink to="/" className={(navData) => (navData.isActive ? 'active' : '')}> 
                    <div className='inactive-deskboard'>
                    <FontAwesomeIcon icon={faTableCells as IconProp} /></div>
                    <div className='active-deskboard'>
                    <FontAwesomeIcon icon={faTableCells as IconProp} /></div> Dashboard</NavLink></li>
                    {/* { (userApiData && userApiData.length > 0 ) && userApiData[0].user.roles[0].role.name === 'SYSTEM_ADMIN' && */}
                    { auth.userData?.profile?.name === 'QWERX Admin' && <li className="list-group-item"><NavLink to="/organizations" className={(navData) => (navData.isActive ? 'active' : '')}>
                    <div className='inactive-org'><FontAwesomeIcon icon={faHouseLaptop as IconProp} /></div>
                    <div className='active-org'><FontAwesomeIcon icon={faHouseLaptop as IconProp} /></div>
                     Organizations</NavLink></li>
                    }
                    
                    {/* } */}
                  

                  <li className="list-group-item" ><NavLink to="/networks" className={(navData) => (navData.isActive ? 'active' : '')}>
                    <div className='inactive-net'><FontAwesomeIcon icon={faNetworkWired as IconProp} /></div>
                    <div className='active-net'><FontAwesomeIcon icon={faNetworkWired as IconProp} /></div>Networks</NavLink></li>

                  <li className="list-group-item"><NavLink to="/devices" className={(navData) => (navData.isActive ? 'active' : '')}>
                    <div className='inactive-div'><FontAwesomeIcon icon={faLaptop as IconProp} /></div>
                    <div className='active-div'><FontAwesomeIcon icon={faLaptop as IconProp} /></div>
                    Devices</NavLink></li>

                  {/* <li className="list-group-item"><NavLink to="/contact" className={(navData) => (navData.isActive ? 'active' : '')}>
                    <div className='inactive-con'><FontAwesomeIcon icon={faAddressBook as IconProp} /></div>
                    <div className='active-con'><FontAwesomeIcon icon={faAddressBook as IconProp} /></div>
                    Contact</NavLink></li> */}

                    <li className="list-group-item"><NavLink to="/audit-logs" className={(navData) => (navData.isActive ? 'active' : '')}>
                    <div className='inactive-con'><FontAwesomeIcon icon={faTableList as IconProp} /></div>
                    <div className='active-con'><FontAwesomeIcon icon={faTableList as IconProp} /></div>
                    Audit Logs</NavLink></li>

                    {/* <li className="list-group-item"><NavLink onClick={() => auth.signOutRedirect()} to={process.env.REACT_APP_OIDC_AUTHORITY_URL !== undefined ? `process.env.REACT_APP_OIDC_AUTHORITY_URL?&client_id=${process.env.REACT_APP_OIDC_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_OIDC_REDIRECT_URL}` : "" } className={(navData) => (navData.isActive ? 'active' : '')}>
                    <div className='inactive-con'><FontAwesomeIcon icon={faSignOut as IconProp} /></div>
                    <div className='active-con'><FontAwesomeIcon icon={faSignOut as IconProp} /></div>
                    Logout</NavLink></li>  */}
                  {/* <li className="list-group-item"><NavLink onClick={() => void auth.signOutRedirect()} to="#"> <div className='inactive-out'></div><div className='active-out'></div>Logout</NavLink></li> */}

                  <li className="list-group-item"><span onClick={() => void auth.signOutRedirect()} className='logout-span' > 
                  <div className='inactive-out'><FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} /></div>
                  <div className='active-out'><FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} /></div>
                  Logout</span></li> 
                </ul>
              </div>
            </div>
            <div key={url} className="col-md-10 imagbg">
              <div className="row">
                <div className="col-md-12">
                  {
                    // (url !== '/' && url !== '') ? null :
                    <h1 className="mt-5 header-text">Welcome back {auth.userData?.profile.name}</h1>
                  }
                  

                  <div className="row">
                    <div className="col-md-12">
                      <Routes>
                        {
                          auth.userData !== undefined && null !== auth.userData &&
                          <>
                            <Route path='/' element={<DashBoardTable key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} />
                            {/* <Route path="/" element={<DashboardComponent key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} /> */}

                            <Route path='/organizations' element={<TenantTable key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} />
                            <Route path='/networks' element={<NetworkDisplayTable key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} />
                            <Route path='/devices' element={<DeviceTable key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} />
                            <Route path='/' element={<DashBoardTable key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} />
                            <Route path='/contact' element={<ContactTable />} />
                            <Route path='/audit-logs' element={<AuditLogTable key={auth.userData?.profile.iat} data={auth.userData ? auth.userData : null} />} />
                           </>
                        }
                      </Routes>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Router>
      }
      
      </>

    
    

  );
}

export default App;