
import orgicon from '../../images/orgicon.svg';
import { useState, ChangeEvent, useEffect, useRef } from 'react';
import './createDevice.css';
import { device } from '../../interface/device';
import axios from 'axios';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faMicrochip, faLaptop } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';


interface createDeviceProps {
  onSave: (message: any) => void;
  userId: string | null;
  userData: any;
}

interface FormState {
  name: string;
  organizationName: string;
  networkName: string;
  macAddress: string;
  description: string;

}

interface DropdownOption {
  value: string;
  label: string;
}

const CreateDevice: React.FC<createDeviceProps> = ({ userData, onSave, userId }) => {

  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      organizationName: '',
      macAddress: '', name: '', networkName: '', email: '', ownerFirstName: '', ownerLastName: '', ownerPhone: '', supervisor: '', department: '', employeeId: '', devicefile: '', bulkorganizationName: '', user_id: ''
    }
  });
  const [options, setOptions] = useState<DropdownOption[]>([]);

  const [networks, setNetworks] = useState<DropdownOption[]>([]);
  const [networkId, setNetworkId] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('create');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [supervisors, setSupervisor] = useState<DropdownOption[]>([]);
  const [shoNetworkMesshage, setShowNetworkMessage] = useState(false)
  const [selectedTenant, setselectedTenant] = useState('')
  const [userExist, setUserExist] = useState(false)
  const [userExistData, setUserExistData] = useState<any>(null)
  const [userExistWithOtherOrg, setUserExistWithOtherOrg] = useState(false);
  const userDetails = useSelector((state: any) => state.userReducer.userData);

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const modalRef = useRef<any>(null);
  const onSubmit = async (data: {
    name: string, organizationName: string, networkName: string, macAddress: string, email: string, ownerFirstName: string, ownerLastName: string, ownerPhone: string, supervisor: string, department: string, employeeId: string, bulkorganizationName: string, devicefile: any, user_id: string
  }) => {
    data.name = capitalizeFirstLetter(data.name);
    if (activeTab === 'create') {
      if (data.networkName !== '' && data.organizationName !== '' && data.macAddress !== '' &&
        data.email !== '' && data.ownerFirstName !== '' && data.ownerLastName !== '' && data.name !== '') {
          if(!userExistWithOtherOrg){
            if (userExist) {
              data.user_id = userExistData.user_id;//userDataOrganization
              const responseData: any = await createNewDevice(data);
              if (!responseData.status) {
                setErrorMessage(responseData.message);
                setSuccessMessage(null);
                return false;
              } else {
                setErrorMessage(null);
                reset();
                if (undefined !== responseData.data)
                  onSave({ status: true, message: 'New device created successfully' });
                  if (modalRef.current) {
                    // Trigger a click event on the modal close button
                    modalRef.current.click();
                  }
                return false;
    
              }
    
            } else {
              const userDataOrganization: any = await createUserForNewDevice(data.organizationName, data);
              if (undefined !== userDataOrganization.response && userDataOrganization.response.data.error) {
                setErrorMessage(userDataOrganization.response.data.error.message);
                setSuccessMessage(null);
                return false;
              } else {
                setValue('ownerFirstName', userDataOrganization.data.user.meta_data.first_name)
                  setValue('ownerLastName', userDataOrganization.data.user.meta_data.last_name)
                  setUserExistData(userDataOrganization.data.user);
                  setUserExist(true);
              
                data.user_id = userDataOrganization.data.user.id;//userDataOrganization
                const responseData: any = await createNewDevice(data);
                if (!responseData.status) {
                  setErrorMessage(responseData.message);
                  setSuccessMessage(null);
                  return false;
                } else {
                  setErrorMessage(null);
                  reset();
                  if (undefined !== responseData.data)
                    onSave({ status: true, message: 'New device created successfully' });
                    if (modalRef.current) {
                      // Trigger a click event on the modal close button
                      modalRef.current.click();
                    }
                  return false;
    
                }
    
              }
    
            }
          }
        



      } else {
        reset();
        setErrorMessage(null);
      }

    } else {
      if (data.bulkorganizationName !== '' && data.devicefile !== '') {
        const bulkresponseData: any = await createNewDeviceBulk(data);
        if (!bulkresponseData.status) {
          setErrorMessage(bulkresponseData.message);
          setSuccessMessage(null);
          return false;
        } else {
            setErrorMessage(null);
            reset();
            if (modalRef.current) {
              // Trigger a click event on the modal close button
              modalRef.current.click();
            }
            if (undefined !== bulkresponseData.data)
              onSave({ status: true, message: 'New device created successfully' });
            return false;
        }
      } else {
        reset();
        setErrorMessage(null);
      }

    }


  };

  const handleSelectElement = (tenantId: string) => {

    // setTenantId(e.target.value);
    setselectedTenant(tenantId)
    //based on the tenantId need to load the networks
    fetchNetworkData(tenantId);
    fetchSupervisorsData(tenantId);
  };
  const handleNetworkElement = (e: ChangeEvent<HTMLSelectElement>) => {
    setNetworkId(e.target.value);

  };


  const createNewDevice = async (formState: any) => {
    try {
      let createDevice = {
        networks: [formState.networkName],
        name: formState.name,
        description: formState.description,
       // mac_address: formState.macAddress,
        mac_addresses: [
          {
            label: "DEFAULT",
            mac_address: formState.macAddress
          }
        ],
        user_id: formState.user_id //formState.supervisor
      }
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantId = formState.organizationName
      const baseURL = `${BASE_URL}/asset-service/tenants/${tenantId}/devices/`;

      const response = await axios.post(baseURL, createDevice, { headers });
      return { status: true, message: 'Device created successfully', data: response }

    } catch (error: any) {
      return { status: false, message: error.response.data.error.message, data: {} }
    }


  }



  const createUserForNewDevice = async (tenant_id: any, formData: any) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const New_Organization_MAP_TO_USER_URL: string = `${BASE_URL}/auth-service/tenants/${tenant_id}/users/`;

      let userOrganization = {
        first_name: formData.ownerFirstName,
        last_name: formData.ownerLastName,
        email: formData.email,
        roles: [process.env.REACT_APP_DEVICE_OWNER], //temporary role hardcoded
        phone: formData.ownerPhone
      }
      const response = await axios.post(New_Organization_MAP_TO_USER_URL, userOrganization, { headers });
      return response;
    } catch (error) {
      return error;
    }

  }

  const createNewDeviceBulk = async (formState: any) => {
    try {

      let createDevice = {
        csv_file: formState.deviceFile,
      }
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
        "Content-Type": "multipart/form-data",
        "mimeType": "multipart/form-data",
      };
      const tenantId = formState.bulkorganizationName
      const baseURL = `${BASE_URL}/asset-service/tenants/${tenantId}/bulk-uploads/validation/`;

      const response = await axios.post(baseURL, createDevice, { headers });

      console.log(response,">>>>>>>>>>>>>>>>")
      return { status: true, message: 'New device created successfully', data: response }

    } catch (error: any) {
      console.log(error,">>>>>>>>>>>>. error")
      return { status: false, message: 'Network error', data: {} }
    }


  }


  useEffect(() => {
    fetchOrganizationData();
  }, [userDetails]);
  //Fetch Organization Details
  const fetchOrganizationData = async () => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenant_id = (userDetails && userDetails[0].user.roles[0].role.name === 'SYSTEM_ADMIN') ? '*' : userDetails[0].user.tenants.length > 0 ? userDetails[0].user.tenants[0].id : null;
      const tenantURL = `${BASE_URL}/auth-service/tenants/?limit=100&page=1&sort_field=name&sort_order=ASC`;
      const response = await axios.get(tenantURL, { headers });
      let organizationsList = response.data.tenants;
      if(tenant_id !== '*'){
        organizationsList = organizationsList.filter((item:any) => (item.id === tenant_id))
      }
      // Transform the data from the API into the required format
      const dropdownOptions: DropdownOption[] = organizationsList.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setOptions(dropdownOptions);
    }
    catch (error) {
      console.error('Error fetching industry data:', error);
    }
  };



  const fetchNetworkData = async (tenantId: string) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const networkURL = `${BASE_URL}/asset-service/tenants/${tenantId}/networks/?page=1&limit=10`;
      try {
        const response = await axios.get(networkURL, {
          headers
        });
        const networkList = response.data.networks;
        const dropdownOptions: DropdownOption[] = networkList.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
        if (dropdownOptions.length <= 0) {
          setShowNetworkMessage(true)
        } else {
          setShowNetworkMessage(false)
        }
        setNetworks(dropdownOptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchSupervisorsData = async (tenantId: string) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };

      const supervisorURL = `${BASE_URL}/auth-service/tenants/${tenantId}/users/`;
      try {
        const response = await axios.get(supervisorURL, {
          headers
        });
        const supervisorList = response.data.users;
        const dropdownOptions: DropdownOption[] = supervisorList.map((item: any) => ({
          value: item.id,
          label: item.display_name,
        }));
        setSupervisor(dropdownOptions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const handleEmailChange = async (emailId: string) => {
    if (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(emailId)) {
      try {
        const token = userData?.access_token;
        const authToken = `Bearer ${token}`;
        const headers = {
          Authorization: authToken,
        };
        const tenantId = selectedTenant;
        const supervisorURL = `${BASE_URL}/auth-service/tenants/${tenantId}/users/?email=${emailId}`;
        try {
          const response = await axios.get(supervisorURL, {
            headers
          });
          const userList = response.data.users;
          if (userList.length > 0) {
            setValue('ownerFirstName', userList[0].meta_data.first_name)
            setValue('ownerLastName', userList[0].meta_data.last_name)
            setUserExistData(userList[0]);
            setUserExist(true)
            setUserExistWithOtherOrg(false);
          } else {
            const supervisorURL =  `${BASE_URL}/auth-service/tenants/*/users/?email=${emailId}`;
            try {
              const response = await axios.get(supervisorURL, {
                headers
              });
              const userList = response.data.users;
              if(userList.length > 0){
                setValue('ownerFirstName', userList[0].meta_data.first_name)
                setValue('ownerLastName', userList[0].meta_data.last_name)
                setUserExistData(userList[0]);
                setUserExist(true)
                setUserExistWithOtherOrg(true);
                setErrorMessage('Email id already in use, please choose some other email id');
                setSuccessMessage(null);
              }else{
                delete errors["email"];
                setUserExistData(null);
                setUserExist(false)
                setValue('ownerFirstName',"")
                setValue('ownerLastName',"")
                setUserExistWithOtherOrg(false);
                setErrorMessage(null);
                setSuccessMessage(null);
              }
            
            }catch (error) {
              console.error('Error fetching data:', error);
              setUserExistData(null);
              setUserExist(false);
              setUserExistWithOtherOrg(false);
              setValue('ownerFirstName',"")
              setValue('ownerLastName',"")
            }
            
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setUserExist(false)
          setUserExistWithOtherOrg(false);
          setUserExistData(null);
          setValue('ownerFirstName',"")
          setValue('ownerLastName',"")
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
        setUserExist(false)
        setUserExistWithOtherOrg(false);
        setUserExistData(null);
        setValue('ownerFirstName',"")
        setValue('ownerLastName',"")
      }
    }else{
      setUserExist(false)
          setUserExistWithOtherOrg(false);
          setUserExistData(null);
          setValue('ownerFirstName',"")
          setValue('ownerLastName',"")
    }

  }

  return (

    <div className="modal fade " id="exampleModalDevice" data-bs-backdrop="static"
      tabIndex={-1} aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><h3 className="titelcolostyle createDeviceHeader">
              <FontAwesomeIcon icon={faLaptop as IconProp} />
              <span className="createDeviceTxt">Devices</span>
            </h3></h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => reset()}></button>
          </div>
          <form className="create-device-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                {null !== errorMessage &&
                  <div>
                    <p style={{ color: 'red' }}>Error: {errorMessage}</p>
                  </div>
                }
                {
                  null !== successMessage &&
                  <div>
                    <p style={{ color: 'blue' }}>Success: {successMessage}</p>
                  </div>
                }
              </div>

              <ul className="nav nav-tabs">
                <li className="nav-item" onClick={() => setActiveTab('create')}>
                  <a className="nav-link active " id="create-tab" data-bs-toggle="tab" data-bs-target="#create" role="tab" aria-controls="create" aria-selected="true" >Create Device</a>
                </li>
                <li className="nav-item" onClick={() => setActiveTab('bulk')}>
                  <a className="nav-link" id="bulk-tab" data-bs-toggle="tab" data-bs-target="#bulk" role="tab" aria-controls="bulk" aria-selected="false">Bulk Upload Devices</a>
                </li>
              </ul>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="create" role="tabpanel" aria-labelledby="create-tab">
                  {/* <form className="create-organization-form" onSubmit={handleSubmit(onSubmit)}> */}
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label" htmlFor="organizationName">Organization<span className="require">*</span></label>
                        <select

                          className={`form-select ${errors.organizationName ? 'is-invalid' : ''}`}
                          id="organizationName"
                          {...register('organizationName', {
                            required: activeTab === 'create' ? true : false,
                          })}
                          onChange={(e) => handleSelectElement(e.target.value)}
                        >
                          <option value="">Select an option</option>
                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.organizationName &&
                          <p className="invalid-feedback d-block errorFont">Organization is required.</p>}
                      </div>
                    </div>
                    <div className="col-md-6">

                      <div className="form-group">
                        <label className="label" htmlFor="networkName">Network<span className="require">*</span></label>
                        <select
                          className={`form-select ${errors.networkName ? 'is-invalid' : ''}`}
                          id="networkName"
                          {...register('networkName', {
                            required: activeTab === 'create' ? true : false,
                          })}
                        >
                          <option value="">Select an option</option>
                          {networks.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {shoNetworkMesshage &&
                          <p className="invalid-feedback d-block errorFont">No network available for selected organization.</p>}
                        {errors.networkName &&
                          <p className="invalid-feedback d-block errorFont">Network is required.</p>}
                      </div>
                    </div>

                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label" htmlFor="name">Device Name<span className="require">*</span></label>
                        <input
                          type="text"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          id="name"
                          {...register('name', {
                            required: activeTab === 'create' ? true : false,
                          })}
                        // value={formState.name}
                        // onChange={handleInputChange}
                        />
                        {errors.name &&
                          <p className="invalid-feedback d-block errorFont">Device name is required.</p>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label" htmlFor="name">MAC Address<span className="require">*</span></label>
                        <input
                          type="text"
                          className={`form-control ${errors.macAddress ? 'is-invalid' : ''}`}
                          id="macAddress"
                          {...register('macAddress', {
                            required: activeTab === 'create' ? true : false,
                          })}
                        />
                        {errors.macAddress &&
                          <p className="invalid-feedback d-block errorFont">macAddress is required.</p>}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label">Email ID <span className="require">*</span></label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Id"
                          {...register('email', {
                            required: activeTab === 'create' ? true : false,
                            pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                          })}
                          onChange={(e) => { handleEmailChange(e.target.value) }}
                        />
                        {errors.email && errors.email.type === "required" &&
                          <p className="invalid-feedback d-block errorFont">Email ID is required.</p>}
                        {errors.email && errors.email.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter correct Email ID.</p>}
                      </div>


                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label" htmlFor="name">Owner First Name<span className="require">*</span></label>
                        <input
                          type="text"
                          className={`form-control ${errors.ownerFirstName ? 'is-invalid' : ''}`}
                          id="ownerFirstName"
                          {...register('ownerFirstName', {
                            required: activeTab === 'create' ? true : false,
                            pattern:/^[a-z]+$/i
                          })}
                        />
                        {errors.ownerFirstName && errors.ownerFirstName.type === "required" &&
                          <p className="invalid-feedback d-block errorFont">Owner first name is required.</p>}
                        {errors.ownerFirstName && errors.ownerFirstName.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter vallid name.</p>}
                       
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label" htmlFor="name">Owner Last Name<span className="require">*</span></label>
                        <input
                          type="text"
                          className={`form-control ${errors.ownerLastName ? 'is-invalid' : ''}`}
                          id="ownerLastName"
                          {...register('ownerLastName', {
                            required: activeTab === 'create' ? true : false,
                            pattern:/^[a-z]+$/i
                          })}
                        />
                        {errors.ownerLastName && errors.ownerLastName.type === "required" &&
                          <p className="invalid-feedback d-block errorFont">Owner last name is required.</p>}
                        {errors.ownerLastName && errors.ownerLastName.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter vallid name.</p>}
                      </div>
                    </div>
                    <div className="col-md-4">
                    
                      <div className="form-group">
                        <>
                        <label className="label" htmlFor="name">Phone No</label>
                        <input
                          type="number"
                          className="form-control"
                          id="ownerPhone"
                          {...register('ownerPhone',{
                          })}
                        />
                        
                          {errors.ownerPhone && errors.ownerPhone.type === "pattern" &&
                          <p className="invalid-feedback d-block errorFont">Enter vallid phone number.</p>}
                          </>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label" htmlFor="name">Employee ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="employeeId"
                          {...register('employeeId')}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label" htmlFor="name">Department</label>
                        <input
                          type="text"
                          className="form-control"
                          id="department"
                          {...register('department')}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label" htmlFor="name">Supervisor Name
                          {/* <span className="require">*</span> */}
                        </label>
                        <select

                          className={`form-select ${errors.supervisor ? 'is-invalid' : ''}`}
                          id="supervisor"
                          {...register('supervisor', {
                            // required: activeTab === 'create' ? userExist ? false: true : false,
                          })}
                        >
                          <option value="">Select an option</option>
                          {supervisors.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.supervisor &&
                          <p className="invalid-feedback d-block errorFont">Supervisor is required.</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="bulk" role="tabpanel" aria-labelledby="bulk-tab">
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <a className="active" style={{ float: 'right' }} href={'https://qwerx-downloads.s3.us-west-2.amazonaws.com/devices/sample.csv'} download="sample">sample csv file <FontAwesomeIcon icon={faDownload as IconProp} /></a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label" htmlFor="organizationName">Organization<span className="require">*</span></label>
                        <select

                          className={`form-select ${errors.bulkorganizationName ? 'is-invalid' : ''}`}
                          id="bulkorganizationName"
                          {...register('bulkorganizationName', {
                            required: activeTab === 'bulk' ? true : false,
                          })}
                          onChange={(e) => handleSelectElement(e.target.value)}
                        >
                          <option value="">Select an option</option>
                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.bulkorganizationName &&
                          <p className="invalid-feedback d-block errorFont">Organization is required.</p>}
                      </div>
                    </div>
                    <div className="col-md-12">

                      <div className="form-group">
                        <label className="label" htmlFor="networkName">Devices<span className="require">*</span></label>
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          {...register('devicefile', {
                            required: activeTab === 'bulk' ? true : false,
                          })}
                        />
                        {errors.devicefile &&
                          <p className="invalid-feedback d-block errorFont">Please choose.</p>}
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              {/* 
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <input
                  type="text"
                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                  id="description"
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                />
                <div className="invalid-feedback">{errors.description}</div>
              </div> */}

              {/* </form> */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light btn-sm"
                data-bs-dismiss="modal" onClick={() => reset()} ref={modalRef}>Cancel</button>
              <button type="submit" className="btn btn-secondary btn-sm">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateDevice;