import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  userData: []
};


const userReducer = (state = initialState, action) => {
    let data;
    switch (action.type) {
      case actionTypes.SET_USER_DATA:
        data = action.data;
        return {
          ...state,
          userData: [data]
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
