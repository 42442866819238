import { useState,useEffect } from "react";
import { Organization } from "../../interface/organization";
import axios from "axios";
import { Modal } from "react-bootstrap";
import './deleteOrganization.css';

interface DeleteOrganizationModalProps {
  userData:any;
  data:Organization;
  onDelete: (id:any) => void;
  isModalOpen:boolean;
  closeModal:any;
  closeDeleteModal:any;
}

const DeleteOrganization: React.FC<DeleteOrganizationModalProps> = ({userData,data,onDelete,isModalOpen,closeModal,closeDeleteModal}) => {

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const BASE_URL = process.env.REACT_APP_SERVICE_ENDPOINT;
  const [deleteModalOpen, setDeleteModalOpen] = useState(isModalOpen);

  useEffect(() => {
    setDeleteModalOpen(isModalOpen);
  }, [isModalOpen]);
    
  const handleDelete = async (organization: Organization) => {
    try {
      const token = userData?.access_token;
      const authToken = `Bearer ${token}`;
      const headers = {
        Authorization: authToken,
      };
      const tenantId = organization.id;
      const baseURL:string = `${BASE_URL}/auth-service/tenants/${tenantId}/`;

      const responseData: any = await axios.delete(baseURL,{headers});
      if (undefined !== responseData.response && responseData.response.data.error) {
        setErrorMessage(responseData.response.data.error.message);
        return false;
      } else {
       // setSuccessMessage("Organization is Deleted");
        setErrorMessage(null);
        onDelete({status:true, message:"Organization deleted successfully"});
      }
    
    } catch (error) {
      console.error('Error deleting network:', error);
    }
  };

    return (

        // <div className="modal fade " id="exampleDeleteOrganization"
        // tabIndex={-1} aria-labelledby="exampleModalLabel"
        // aria-hidden="true">
        <div>
            <Modal dialogClassName='delete-dialog' backdrop="static" show={deleteModalOpen} onHide={() => { closeDeleteModal(); setDeleteModalOpen(false) }} style={{marginTop:'5%'}}>
  
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <h3 className="titelcolostyle"> Confirm Deletion</h3></h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <button type="button" className="btn-close"  aria-label="Close" onClick={() => {closeDeleteModal(); setDeleteModalOpen(false) }}></button>
            </div>
            <div className="modal-body">
            {null !== errorMessage &&
              <div>
                <p style={{ color: 'red' }}>Error: {errorMessage}</p>
              </div>
            }
            {
              null !== successMessage &&
              <div>
                <p style={{ color: 'blue' }}>success: {successMessage}</p>
              </div>
            }
              <h2 style={{ fontSize: '17px'}}>Are you sure you want to delete this organization?</h2>
              
            </div>
            <div className="modal-footer">
                {/* <button type="button" className="btn btn-danger"
                onClick={() => handleDelete(data)}  data-bs-dismiss="modal">Yes</button> */}
                 <button type="button" className="btn btn-danger"
                onClick={() => {handleDelete(data);closeDeleteModal();setDeleteModalOpen(false);closeModal(); }}  >Yes</button>
                {/* <button type="button" className="btn btn-primary"
                data-bs-dismiss="modal">No</button> */}
                 <button type="button" className="btn btn-light btn-sm" onClick={() => {closeDeleteModal(); setDeleteModalOpen(false) }}>No</button>
            </div>
           
        
        </Modal>
      </div>
    );
  };
  
  export default DeleteOrganization;